import { useStore } from '../../store'

const LogoPetals = ({ accentColor = 'var(--accent-color)', width = 54 }) => {
  const ratio = 141.11 / 135.16
  const height = width / ratio

  const fillColor = useStore((state) => state.logoFillColor)

  return (
    <a href="/">
      <svg
        width={width}
        height={height}
        data-name="Logo Dariah lu Petals"
        viewBox="0 0 141.11 135.16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m70.56,0c-8.62,5.23-21.72,11.51-21.72,21.69,0,11.07,4.85,32.04,9.14,32.04,3.95,0,9.44-24.51,12.58-24.58,3.14.07,8.63,24.59,12.58,24.58,4.29,0,9.14-20.97,9.14-32.04,0-10.17-13.1-16.46-21.72-21.69Z"
          fill={fillColor}
        />
        <path
          d="m141.11,51.5c-7.61-6.62-17.59-17.18-27.28-14.08-10.55,3.38-29.03,14.39-27.73,18.47,1.2,3.76,26.22,1.51,27.25,4.49.9,3.02-20.78,15.72-19.58,19.48,1.31,4.08,22.76,2.31,33.3-1.06,9.69-3.1,11.69-17.49,14.03-27.3Z"
          fill={fillColor}
        />
        <path
          d="m113.94,134.13c3.93-9.29,10.86-22.05,4.91-30.3-6.48-8.98-22.7-23.12-26.17-20.62-3.2,2.31,6.71,25.4,4.2,27.29-2.59,1.79-21.4-14.87-24.59-12.56-3.47,2.51,4.87,22.35,11.35,31.32,5.96,8.25,20.26,5.67,30.31,4.86Z"
          fill={fillColor}
        />
        <path
          d="m27.17,134.13c10.05.81,24.35,3.39,30.31-4.86,6.48-8.98,14.82-28.81,11.35-31.32-3.2-2.32-22.01,14.34-24.59,12.56-2.51-1.9,7.4-24.99,4.2-27.29-3.48-2.5-19.69,11.64-26.17,20.62-5.96,8.25.98,21.02,4.91,30.3Z"
          fill={fillColor}
        />
        <path
          d="m0,51.5c2.35,9.81,4.34,24.2,14.03,27.3,10.55,3.38,31.99,5.14,33.3,1.06,1.21-3.76-20.47-16.46-19.58-19.48,1.02-2.97,26.05-.73,27.25-4.49,1.3-4.08-17.18-15.1-27.73-18.47-9.69-3.1-19.67,7.46-27.28,14.08Z"
          fill={accentColor}
        />
      </svg>
    </a>
  )
}

export default LogoPetals
