import './PostCover.css'

const PostCover = ({ content = { rendered: '' }, size = '1024w', className = '', ...props }) => {
  const coverSetsMatch = content.rendered.match(/srcset="([^"]*)"/)
  if (!coverSetsMatch) {
    console.debug('[PostCover] \n - no available cover in content, skipping.')
    return null
  }
  const coverSets = coverSetsMatch[1].split(',').reduce((acc, src) => {
    const [url, size] = src.trim().split(' ')
    acc[size] = { url, size: parseInt(size.replace(/[^\d]$/g, ''), 10) }
    return acc
  }, {})

  console.debug('[PostCover] \n - available sizes:', Object.keys(coverSets))
  // if one of the size matches the desired size, return it
  if (coverSets[size]) {
    return (
      <figure className={`PostCover ${className}`} {...props}>
        <img src={coverSets[size].url} className="img-fluid" alt="..." />
      </figure>
    )
  }
  // find the closest size to the desired size
  const numericSize = parseInt(size, 10)
  const closestSize = Object.values(coverSets).sort(
    (a, b) => Math.abs(a.size - numericSize) - Math.abs(b.size - numericSize),
  )[0]

  // find the smallest size
  console.debug('[PostCover] \n - closest size:', closestSize)
  return (
    <figure className={`PostCover ${className}`} {...props}>
      <img src={closestSize.url} className="img-fluid" alt="..." />
    </figure>
  )
}

export default PostCover
