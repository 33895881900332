import { useTranslation } from 'react-i18next'
import './PostListItem.css'
import PostCategories from './PostCategories'
import { useNavigate } from 'react-router-dom'
import { PostRoute } from '../constants'
import ArrowSign from './svg/ArrowRight'

const PostListItem = ({
  slug,
  date = '',
  date_gmt = '',
  modified_gmt = '',
  title = { rendered: '' },
  excerpt = { rendered: '' },
  content = { rendered: '' },
  categories = [],
  hideCover = false,
  ...rest
}) => {
  const { t } = useTranslation()
  // get the 300w image
  const coverSet = content.rendered
    .match(/srcset="([^"]*)"/)?.[1]
    .split(',')
    .find((src) => src.includes('1024w'))

  const coverUrl = coverSet?.match(/https?:\/\/[^ ]*/)?.[0]
  const excerptWithoutContinued = excerpt.rendered.replace(/<a[^>]*>Continued*<\/a>/, '')
  const navigate = useNavigate()

  const gotoPostPage = () => {
    console.debug('[PostListItem] gotoPostPage', slug)
    navigate(PostRoute.path.replace(':slug', slug))
  }
  return (
    <div className="PostListItem card mb-3">
      <div className="row g-0">
        {!hideCover && typeof coverUrl !== 'undefined' && (
          <div className="col-md-4 p-3" onClick={gotoPostPage}>
            <figure className="PostListItem_imgWrapper">
              <img src={coverUrl} className="img-fluid" alt="..." />
            </figure>
          </div>
        )}
        <div className={hideCover || typeof coverUrl === 'undefined' ? 'col-md-12' : ' col-md-8'}>
          <div className="card-body">
            <div className="d-flex align-items-baseline justify-content-between">
              <PostCategories
                rule={(category) => {
                  return (
                    category.parent === parseInt(import.meta.env.VITE_WP_RESOURCES_CATEGORY_ID, 10)
                  )
                }}
                className="PostListItem_categories text-small"
                ids={categories}
                to={({ slug }) => `/resources/${slug}`}
              />

              <div className="text-small">{t('dateShort', { date: new Date(date_gmt) })}</div>
            </div>
            <h5
              onClick={gotoPostPage}
              className="card-title my-3 link-primary"
              dangerouslySetInnerHTML={{ __html: title.rendered }}
            />
            <p
              className="card-text"
              dangerouslySetInnerHTML={{ __html: excerptWithoutContinued }}
            />
            <p className="card-text">
              <small className="text-muted"></small>
            </p>
            <div className="clickArea d-flex justify-content-between" onClick={gotoPostPage}>
              <button className="btn btn-link">{t('readMore')}</button>
              <button className="PostListItem_arrow btn btn-transparent">
                <ArrowSign />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PostListItem
