export default {
  en: {
    translation: {
      actionCookieConsent: 'Accept',
      cookieConsentAnalytics: 'I accept the use of Google Analitycs',
      helloworld: 'Welcome to Dariah',
      HomeRoute: 'Home',
      AboutRoute: 'About',
      NewsEventsRoute: 'News & events',
      NewsRoute: 'News',
      ResourcesRoute: 'Resources',
      ContactUsRoute: 'Contact Us',
      TermsOfUseRoute: 'Terms of use',
      contact: 'Get in touch',
      joinDariah: 'Join DARIAH-LU consortium',
      ConsortiumRoute: 'The consortium',
      JoinUsRoute: 'Join us',
      latestNewsEvents: 'Latest News & Events',
      latestPosts: 'Latest Posts',
      latestResources: 'Latest Resources',
      meetTheTeam: 'Meet the DARIAH-LU team',
      nextPost: 'Next post',
      previousPost: 'Previous post',
      readMore: 'Read more ...',
      readMoreCookieConsent: 'Read more',
      'category-resources': 'All resources',
      'category-projects-training-material': 'Projects and training material',
      'category-tools-workflows': 'Tools and workflows',
      'category-news-events': 'News and events',
      'category-news': 'News',
      'category-events': 'Events',
      dateShort: '{{ date,short }}',
      textCookieConsent:
        'This website uses cookies to ensure you get the best experience on our website. By continuing to browse the site, you are agreeing to our use of cookies.',
    },
  },
  fr: {
    translation: {
      actionCookieConsent: 'Accepter',
      cookieConsentAnalytics: "J'accepte l'usage de Google Analitycs",

      helloworld: 'Bienvenue à Dariah',
      HomeRoute: 'Accueil',
      AboutRoute: 'À propos',
      NewsEventsRoute: 'News & events',
      NewsRoute: 'Nouvelles',
      ResourcesRoute: 'Ressources',
      ContactUsRoute: 'Contactez-nous',
      TermsOfUseRoute: "Conditions d'utilisation",
      contact: 'Nous contacter',
      joinDariah: 'Join DARIAH-LU consortium',
      ConsortiumRoute: 'Le consortium',
      JoinUsRoute: 'Rejoignez-nous',
      latestNewsEvents: 'Dernières nouvelles et événements',
      latestPosts: 'Derniers postes',
      latestResources: 'Dernières ressources',
      meetTheTeam: 'Rencontrez l’équipe DARIAH-LU',
      nextPost: 'Next post',
      previousPost: 'Previous post',
      readMore: 'Lire la suite ...',
      readMoreCookieConsent: 'En savoir plus',
      'category-resources': 'toutes les ressources',
      'category-projects-training-material': 'projets et matériel de formation',
      'category-tools-workflows': 'outils et flux de travail',
      'category-news-events': 'nouvelles et événements',
      'category-news': 'nouvelles',
      'category-events': 'événements',
      dateShort: '{{ date,short }}',
      textCookieConsent:
        "Nous utilisons des cookies pour améliorer votre expérience. En poursuivant votre navigation sur ce site, vous acceptez l'utilisation de cookies. Pour plus d'informations, veuillez visiter la page <a href='/page/terms-of-use'>Conditions d'utilisation</a>.",
    },
  },
  de: {
    translation: {
      actionCookieConsent: 'Akzeptieren',
      cookieConsentAnalytics: 'Ich akzeptiere die Verwendung von Google Analitycs',
      helloworld: 'Willkommen bei Dariah',
      HomeRoute: 'Zuhause',
      AboutRoute: 'Über',
      NewsEventsRoute: 'News & events',
      NewsRoute: 'Nachrichten',
      ResourcesRoute: 'Ressourcen',
      ContactUsRoute: 'Kontaktiere uns',
      TermsOfUseRoute: 'Nutzungsbedingungen',
      contact: 'Kontakt',
      joinDariah: 'Join DARIAH-LU consortium',
      ConsortiumRoute: 'Das Konsortium',
      JoinUsRoute: 'Mitmachen',
      latestNewsEvents: 'Neueste Nachrichten & Veranstaltungen',
      latestPosts: 'Neueste Beiträge',
      latestResources: 'Neueste Ressourcen',
      meetTheTeam: 'Lernen Sie das DARIAH-LU Team kennen!',
      nextPost: 'Next post',
      previousPost: 'Previous post',
      readMore: 'Weiterlesen ...',
      readMoreCookieConsent: 'Mehr erfahren',
      'category-resources': 'alle Ressourcen',
      'category-projects-training-material': 'Projekte und Trainingsmaterial',
      'category-tools-workflows': 'Tools und Workflows',
      'category-news-events': 'Nachrichten und Veranstaltungen',
      'category-news': 'Nachrichten',
      'category-events': 'Veranstaltungen',
      dateShort: '{{ date,short }}',
      textCookieConsent:
        'Diese Website verwendet Cookies, um sicherzustellen, dass Sie die beste Erfahrung auf unserer Website erhalten. Wenn Sie die Website weiterhin durchsuchen, stimmen Sie der Verwendung von Cookies zu.',
    },
  },
  lb: {
    translation: {
      actionCookieConsent: 'Acceptéieren',
      cookieConsentAnalytics: "Ech akzeptéieren d'Verwendung vun Google Analitycs",
      helloworld: 'Wëllkomm bei Dariah',
      HomeRoute: 'Doheem',
      AboutRoute: 'Iwwer',
      NewsEventsRoute: 'News & events',
      NewsRoute: 'Neiegkeeten',
      ResourcesRoute: 'Ressourcen',
      ContactUsRoute: 'Kontaktéiert eis',
      TermsOfUseRoute: 'Benotzungsconditiounen',
      contact: 'Kont',
      joinDariah: 'Join DARIAH-LU consortium',
      ConsortiumRoute: 'De Konsortium',
      JoinUsRoute: 'Matmaachen',
      latestNewsEvents: '',
      latestPosts: 'Lescht Posten',
      latestResources: 'Lescht Ressourcen',
      meetTheTeam: 'Meet the DARIAH-LU team',
      nextPost: 'Next post',
      previousPost: 'Previous post',
      readMore: 'Lies méi ...',
      readMoreCookieConsent: 'Méi erfueren',
      'category-resources': 'all Ressourcen',
      'category-projects-training-material': 'Projekten an Ausbilungs-Material',
      'category-tools-workflows': 'Tools an Workflows',
      'category-news-events': 'Neiegkeeten an Evenementer',
      'category-news': 'Neiegkeeten',
      'category-events': 'Evenementer',
      dateShort: '{{ date,short }}',
      textCookieConsent:
        "Dës Websäit benotzt Cookies fir ze garantéieren, dass Dir d'bescht Erfarung op eiser Websäit krut. Wann Dir weider op der Säit bleift, erkläert Dir Iech mat der Benotzung vu Cookies enverstanen.",
    },
  },
}
