import { useQuery } from '@tanstack/react-query'
import { useCurrentLanguage } from '../utils/i18n'
import Page from './Page'
import { axiosInstance } from '../utils/api'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import TeamMember from '../components/TeamMember'

const About = ({ slug }) => {
  const { t } = useTranslation()
  const language = useCurrentLanguage()
  const { status: peopleStatus, data: people } = useQuery({
    queryKey: ['people', language],
    queryFn: ({ signal }) => {
      return axiosInstance
        .get('/posts', {
          signal,
          params: {
            categories: [import.meta.env.VITE_WP_PEOPLE_CATEGORY_ID],
            per_page: 30,
          },
        })
        .then(({ data }) => data.filter((post) => post.slug.indexOf(`-${language}`) !== -1))
    },
  })
  return (
    <Page slug={slug}>
      <Container className="pb-4">
        <Row>
          <Col>
            <h2 className="text-primary mb-5">{t('meetTheTeam')}</h2>
          </Col>
        </Row>
        <Row>
          {peopleStatus === 'success' &&
            people
              .sort((a, b) => (a.slug > b.slug ? -1 : 1))
              .map((person) => (
                <Col key={person.id} className="mb-3" md={{ span: 6 }}>
                  <TeamMember person={person} fluid />
                </Col>
              ))}{' '}
        </Row>
      </Container>
    </Page>
  )
}

export default About
