const LogoBnl = ({ width = 140 }) => {
  const ratio = 221 / 106.9
  const height = width / ratio
  const color = 'var(--tertiary-color)'
  return (
    <a href="https://bnl.public.lu/en.html" target="blank">
      <svg
        id="LogoBnl"
        width={width}
        height={height}
        data-name="Logo BNL"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 221 106.9"
      >
        <path
          fill={color}
          d="M43.8,39h-6.5v-9.8c0-1.6-1.3-2.9-2.9-2.9h-2.9V39h-6.4V20.3h9.3c5-0.2,9.1,3.7,9.3,8.7
	c0,0.1,0,0.2,0,0.2V39z M42.3,81.8H25.2v-6.2h0v-13h6.4v13h10.7L42.3,81.8z M53.5,50.7c4.2-1.3,5.8-2.3,8.5-4.8
	c4.1-4.1,6.3-9.7,6.2-15.5c0.2-6.4-2.6-12.4-7.6-16.5C55.1,9,49.2,7.3,38,7.3H6.8v92.3h29.1c5,0,8.9-0.1,11.8-0.3
	c16.2-1.1,24.1-12.7,24.1-25.5C71.8,61.8,65.8,54.4,53.5,50.7"
        />
        <path
          fill={color}
          d="M172.4,82.3c0.5,0,0.6,0.1,0.6,0.4c0,0.4-0.5,0.6-1.3,0.6c-1,0-1.5-0.2-1.5-0.5
	c0-0.3,0.2-0.4,0.3-0.6L172.4,82.3z M174.7,74.4c-0.6,0-1.1,0.3-1.4,0.8c-0.5-0.5-1.2-0.8-1.9-0.8c-1.9,0-2.4,1.2-2.4,2.7
	c-0.1,1,0.3,1.9,1.1,2.5c-0.4,0.2-1,0.6-1,1.2c0,0.4,0.3,0.8,0.7,1c-0.4,0.3-1,0.6-1,1.1c0,0.8,1,1.3,2.7,1.3c1.9,0,3.2-0.6,3.2-1.8
	c0-2.6-4.2-1.2-4.2-2.1c0-0.2,0.2-0.3,0.3-0.4c0.2,0.1,0.5,0.1,0.7,0.1c1.6,0,2.4-1,2.4-2.8c0-0.4-0.1-0.9-0.2-1.3
	c0.2-0.1,0.4-0.1,0.6-0.2c0.2,0,0.3,0.1,0.5,0.1L174.7,74.4z M172.2,77.2c0,1.3-0.2,1.5-0.9,1.5c-0.5,0-0.8-0.4-0.8-1.4
	c0-1.1,0.2-1.6,0.9-1.6C171.9,75.8,172.2,76.2,172.2,77.2 M166.1,81.8v-4.9c0-0.4,0.3-0.8,0.8-0.8c0,0,0,0,0,0
	c0.3,0,0.6,0.1,0.9,0.2v-1.8c-0.7,0-1.4,0.4-1.7,1.1h0v-1h-1.6v7.2L166.1,81.8z M161.2,74.6V80c-0.3,0.3-0.7,0.6-1.1,0.6
	c-0.4,0-0.6-0.3-0.6-0.8v-5.3h-1.7v5.5c0,1.3,0.5,1.9,1.5,1.9c0.7-0.1,1.4-0.4,1.9-1v0.8h1.6v-7.2L161.2,74.6z M152.8,76.7
	c0-0.6,0.4-0.8,1-0.8s1,0.3,1,0.8v2.9c0,0.6-0.4,0.8-1,0.8s-1-0.3-1-0.8V76.7z M151.1,79.2c0,1.9,1,2.7,2.6,2.7s2.6-0.8,2.6-2.7v-2
	c0-1.9-1-2.7-2.6-2.7s-2.6,0.8-2.6,2.7V79.2z M146.1,81.8v-0.9h0c0.4,0.6,1.1,1,1.8,1c1.3,0,1.8-1,1.8-2.9v-1.8
	c0-1.9-0.5-2.9-1.8-2.9c-0.6,0.1-1.2,0.4-1.7,0.8v-3h-1.7v9.6H146.1z M146.3,76.2c0.2-0.3,0.6-0.5,1-0.5c0.4,0,0.8,0.3,0.8,1.7v1.5
	c0,1.4-0.4,1.7-0.8,1.7c-0.4,0-0.8-0.2-1-0.5V76.2z M135.1,81.8h1.7v-5.5c0.2-0.3,0.6-0.6,1-0.6c0.3,0,0.4,0.1,0.4,0.6v5.5h1.7v-5.5
	c0.3-0.3,0.6-0.6,1-0.6c0.3,0,0.4,0.1,0.4,0.6v5.5h1.7v-5.4c0-1.3-0.5-1.9-1.5-1.9c-0.7,0.1-1.4,0.5-1.8,1.1
	c-0.1-0.6-0.6-1.1-1.3-1.1c-0.7,0.1-1.3,0.4-1.8,1v-0.8h-1.6L135.1,81.8z M130,77.2v-0.4c0-0.6,0.3-1,1-1s1,0.4,1,1v0.4H130z
	 M133.6,78.6v-1.7c-0.1-1.5-1.4-2.5-2.8-2.5c-1.4,0.1-2.4,1.2-2.5,2.6v2.4c-0.1,1.3,0.9,2.5,2.3,2.6c0.1,0,0.2,0,0.3,0
	c1.3,0.2,2.4-0.7,2.6-2c0-0.1,0-0.2,0-0.4h-1.7c0.1,0.5-0.3,0.9-0.8,1c-0.1,0-0.1,0-0.2,0c-0.5,0-0.9-0.3-1-0.9c0-0.1,0-0.1,0-0.2
	v-1L133.6,78.6z M123.9,78.1l-2,3.7h1.8l1.1-2.3l1.1,2.3h1.8l-1.9-3.7l1.8-3.5h-1.8l-1,2.1l-0.9-2.1h-1.8L123.9,78.1z M119.1,74.6
	V80c-0.4,0.3-0.7,0.6-1.1,0.6c-0.4,0-0.6-0.3-0.6-0.8v-5.3h-1.7v5.5c0,1.3,0.5,1.9,1.5,1.9c0.7-0.1,1.4-0.4,1.9-1v0.8h1.6v-7.2
	L119.1,74.6z M114.5,81.8v-1.6h-2.9v-8h-1.8v9.6H114.5z M97.9,81.8v-9.6h-1.7v3c-0.5-0.4-1-0.7-1.7-0.8c-1.3,0-1.8,1-1.8,2.9V79
	c0,1.9,0.5,2.9,1.8,2.9c0.7-0.1,1.4-0.4,1.8-1h0v0.9L97.9,81.8z M96.3,80.1c-0.2,0.3-0.6,0.5-1,0.5c-0.4,0-0.8-0.3-0.8-1.7v-1.5
	c0-1.4,0.4-1.7,0.8-1.7c0.4,0,0.8,0.2,1,0.5V80.1z"
        />
        <path
          fill={color}
          d="M210.6,61.3v-0.4c0-0.6,0.3-1,1-1s1,0.4,1,1v0.4H210.6z M214.2,62.6V61
	c-0.1-1.5-1.4-2.5-2.8-2.5c-1.4,0.1-2.4,1.2-2.5,2.6v2.4c-0.1,1.3,0.9,2.5,2.3,2.6c0.1,0,0.2,0,0.3,0c1.3,0.2,2.4-0.7,2.6-2
	c0-0.1,0-0.2,0-0.4h-1.7c0.1,0.5-0.3,0.9-0.8,1c-0.1,0-0.1,0-0.2,0c-0.5,0-0.9-0.3-1-0.9c0-0.1,0-0.1,0-0.2v-1H214.2z M205.7,65.8
	h1.7v-9.6h-1.7V65.8z M202.3,64.1c-0.3,0.4-0.8,0.6-1.3,0.7c-0.4,0-0.7-0.3-0.7-0.6c0,0,0-0.1,0-0.1c0-0.8,1-1.3,2-1.7V64.1z
	 M200.5,60.7c0-0.5,0.4-0.9,0.9-0.9c0,0,0,0,0,0c0.4,0,0.8,0.3,0.9,0.7c0,0.1,0,0.1,0,0.2v0.5c-2.1,0.6-3.6,1.3-3.6,3
	c-0.1,0.8,0.5,1.6,1.3,1.7c0.1,0,0.2,0,0.3,0c0.7,0,1.5-0.3,2-0.8c0.1,0.3,0.1,0.5,0.1,0.7h1.7c-0.1-0.2-0.1-0.4-0.1-0.6v-4.6
	c0-1.3-0.7-2.2-2.4-2.2c-0.6,0-1.3,0.2-1.8,0.5c-0.5,0.4-0.9,1-0.9,1.7L200.5,60.7z M194,65.8v-5.5c0.4-0.3,0.7-0.6,1.1-0.6
	c0.4,0,0.6,0.3,0.6,0.8v5.3h1.7v-5.5c0-1.3-0.5-1.9-1.5-1.9c-0.7,0.1-1.4,0.4-1.9,1v-0.8h-1.6v7.2L194,65.8z M187.2,60.8
	c0-0.6,0.4-0.8,1-0.8s1,0.3,1,0.8v2.9c0,0.6-0.4,0.9-1,0.9s-1-0.3-1-0.9V60.8z M185.6,63.2c0,1.9,1,2.7,2.6,2.7s2.6-0.8,2.6-2.7v-2
	c0-1.9-1-2.7-2.6-2.7s-2.6,0.8-2.6,2.7V63.2z M183.9,55.9h-1.7v1.5h1.7V55.9z M182.3,65.8h1.7v-7.2h-1.7V65.8z M178.3,60.3v4.2
	c0,1,0.7,1.4,1.8,1.4c0.3,0,0.6,0,0.9-0.1v-1.4c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8-0.2-0.8-0.5v-3.7h1.1v-1.4h-1.1v-2h-1.7v2h-0.7v1.4
	L178.3,60.3z M174.8,64.1c-0.3,0.4-0.8,0.6-1.3,0.7c-0.4,0-0.7-0.3-0.7-0.6c0,0,0-0.1,0-0.1c0-0.8,1-1.3,2-1.7L174.8,64.1z
	 M173,60.7c0-0.5,0.4-0.9,0.9-0.9c0,0,0,0,0,0c0.4,0,0.8,0.3,0.9,0.7c0,0.1,0,0.1,0,0.2v0.5c-2.1,0.6-3.6,1.3-3.6,3
	c-0.1,0.8,0.5,1.6,1.3,1.7c0.1,0,0.2,0,0.3,0c0.7,0,1.5-0.3,2-0.8c0.1,0.3,0.1,0.5,0.1,0.7h1.7c-0.1-0.2-0.1-0.4-0.1-0.6v-4.6
	c0-1.3-0.7-2.2-2.4-2.2c-0.6,0-1.3,0.2-1.8,0.5c-0.5,0.4-0.9,1-0.9,1.7L173,60.7z M166.5,65.8v-5.5c0.4-0.3,0.7-0.6,1.1-0.6
	c0.4,0,0.6,0.3,0.6,0.8v5.3h1.7v-5.5c0-1.3-0.5-1.9-1.5-1.9c-0.7,0.1-1.4,0.4-1.9,1v-0.8h-1.6v7.2L166.5,65.8z M156.1,61.3v-0.4
	c0-0.6,0.3-1,1-1s1,0.4,1,1v0.4H156.1z M159.7,62.6V61c-0.1-1.5-1.4-2.5-2.8-2.5c-1.4,0.1-2.4,1.2-2.5,2.6v2.4
	c-0.1,1.3,0.9,2.5,2.3,2.6c0.1,0,0.2,0,0.3,0c1.3,0.2,2.4-0.7,2.6-2c0-0.1,0-0.2,0-0.4H158c0.1,0.5-0.3,0.9-0.8,1
	c-0.1,0-0.1,0-0.2,0c-0.5,0-0.9-0.3-1-0.9c0-0.1,0-0.1,0-0.2v-1H159.7z M151.3,58.6v5.5c-0.4,0.3-0.7,0.7-1.1,0.7
	c-0.4,0-0.6-0.3-0.6-0.8v-5.3h-1.7v5.5c0,1.3,0.5,1.9,1.5,1.9c0.7-0.1,1.4-0.4,1.9-1v0.8h1.6v-7.2H151.3z M144.8,58.6v0.9h0
	c-0.4-0.6-1.1-1-1.8-1c-1.3,0-1.8,1-1.8,2.9v1.8c0,1.9,0.5,2.9,1.8,2.9c0.6-0.1,1.2-0.4,1.7-0.8v3.2h1.7v-9.8H144.8z M144.7,64.2
	c-0.2,0.3-0.6,0.5-1,0.5c-0.4,0-0.8-0.3-0.8-1.7v-1.5c0-1.4,0.4-1.7,0.8-1.7c0.4,0,0.8,0.2,1,0.5V64.2z M135.1,55.8l1.9,1.9h1.3
	l-1.2-1.9L135.1,55.8z M136.2,61.3v-0.4c0-0.6,0.3-1,1-1s1,0.4,1,1v0.4H136.2z M139.8,62.6V61c-0.1-1.5-1.4-2.5-2.8-2.4
	c-1.3,0.1-2.4,1.2-2.4,2.5v2.4c-0.1,1.3,0.9,2.5,2.3,2.6c0.1,0,0.2,0,0.3,0c1.3,0.2,2.4-0.7,2.6-2c0-0.1,0-0.2,0-0.4h-1.7
	c0.1,0.5-0.3,0.9-0.8,1c-0.1,0-0.1,0-0.2,0c-0.5,0-0.9-0.3-1-0.9c0-0.1,0-0.1,0-0.2v-1L139.8,62.6z M129.7,65.9v-5.5
	c0.4-0.3,0.7-0.6,1.1-0.6c0.4,0,0.6,0.3,0.6,0.8v5.3h1.7v-5.5c0-1.3-0.5-1.9-1.5-1.9c-0.7,0.1-1.3,0.4-1.8,1v-3.2h-1.7v9.6
	L129.7,65.9z M124.2,60.3v4.2c0,1,0.7,1.4,1.8,1.4c0.3,0,0.6,0,0.9-0.1v-1.4c-0.1,0-0.2,0-0.3,0c-0.4,0-0.7-0.2-0.7-0.5v-3.7h1.1
	v-1.4h-1.1v-2h-1.7v2h-0.7v1.4L124.2,60.3z M118.8,60.8c0-0.6,0.4-0.8,1-0.8s1,0.3,1,0.8v2.9c0,0.6-0.4,0.9-1,0.9s-1-0.3-1-0.9V60.8
	z M117.2,63.2c0,1.9,1,2.7,2.6,2.7s2.6-0.8,2.6-2.7v-2c0-1.9-1-2.7-2.6-2.7s-2.6,0.8-2.6,2.7V63.2z M115.6,55.9h-1.7v1.5h1.7V55.9z
	 M113.9,65.8h1.7v-7.2h-1.7V65.8z M110.5,65.8h1.7v-9.6h-1.7V65.8z M105.2,65.8V65h0c0.4,0.6,1.1,1,1.8,1c1.3,0,1.8-1,1.8-2.9v-1.8
	c0-1.9-0.5-2.9-1.8-2.9c-0.6,0.1-1.2,0.4-1.7,0.8v-3h-1.7v9.6L105.2,65.8z M105.4,60.3c0.2-0.3,0.6-0.5,1-0.5c0.4,0,0.8,0.3,0.8,1.7
	V63c0,1.4-0.4,1.7-0.8,1.7c-0.4,0-0.8-0.2-1-0.5L105.4,60.3z M102,55.9h-1.7v1.5h1.7V55.9z M100.3,65.8h1.7v-7.2h-1.7V65.8z
	 M94.8,57.8h0.7c0.9,0,1.3,0.5,1.3,1.2c0,0.7-0.6,1.2-1.3,1.1c0,0-0.1,0-0.1,0h-0.6V57.8z M94.8,61.6h0.6c0.9,0,1.6,0.5,1.6,1.4
	c0.1,0.7-0.5,1.3-1.2,1.4c-0.1,0-0.2,0-0.3,0h-0.8V61.6z M92.9,65.9h2.7c1.9,0,3.2-0.9,3.2-2.8c0.1-1-0.5-1.9-1.4-2.4
	c0.7-0.4,1.2-1.2,1.2-2c0-1.6-1.1-2.5-3.1-2.5h-2.6L92.9,65.9z"
        />
        <path
          fill={color}
          d="M102.8,74.6V80c-0.4,0.3-0.7,0.6-1.1,0.6c-0.4,0-0.6-0.3-0.6-0.8v-5.3h-1.7v5.5
	c0,1.3,0.5,1.9,1.5,1.9c0.7-0.1,1.4-0.4,1.9-1v0.8h1.6v-7.2H102.8z"
        />
      </svg>
    </a>
  )
}

export default LogoBnl
