import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useStore } from '../store'

const LocationWatcher = () => {
  const location = useLocation()
  const pathname = location.pathname
  const setLogoFillColor = useStore((state) => state.setLogoFillColor)
  const isMenuOpen = useStore((state) => state.isMenuOpen)

  useEffect(() => {
    console.log('[LocationWatcher] @useEffect pathname only, pathname', pathname)
    document.body.className = pathname.replace(/^\//, '').split('/').join(' ')
    if (isMenuOpen) {
      setLogoFillColor('var(--primary-color)')
      return
    }
    const fillColor = ['/page/', '/post/', '/about', '/contact-us'].some((d) =>
      pathname.startsWith(d),
    )
      ? 'var(--tertiary-color)'
      : 'var(--primary-color)'
    setLogoFillColor(fillColor)
  }, [pathname, isMenuOpen])

  return null
}

export default LocationWatcher
