import './ScrollToButton.css'

const ScrollToButton = ({
  onClick,
  strokeColor = 'var(--tertiary-color)',
  width = 44,
  className = 'ScrollToBottom',
  ...props
}) => {
  return (
    <div className={`ScrollTo ${className}`} {...props}>
      <svg
        width={width}
        height={width}
        data-name="Scroll Up Down"
        viewBox="0 0 58 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
      >
        <rect
          x="56.9292"
          y="56.3101"
          width="55"
          height="55"
          rx="27.5"
          transform="rotate(-180 56.9292 56.3101)"
          stroke={strokeColor}
          strokeWidth="2"
        />
        <path
          d="M30.6363 16.603C30.2458 16.2124 29.6126 16.2124 29.2221 16.603L22.8581 22.9669C22.4676 23.3574 22.4676 23.9906 22.8581 24.3811C23.2487 24.7716 23.8818 24.7716 24.2723 24.3811L29.9292 18.7243L35.5861 24.3811C35.9766 24.7717 36.6097 24.7717 37.0003 24.3811C37.3908 23.9906 37.3908 23.3574 37.0003 22.9669L30.6363 16.603ZM30.9292 40.3101L30.9292 17.3101L28.9292 17.3101L28.9292 40.3101L30.9292 40.3101Z"
          fill={strokeColor}
        />
      </svg>
    </div>
  )
}

export default ScrollToButton
