import React, { Suspense, useEffect, useMemo, useLayoutEffect } from 'react'
import './World.css'
import { Canvas, useThree, useFrame } from '@react-three/fiber'
import { FlowerModel } from './FlowerModel'
import { AsciiEffect } from 'three-stdlib'

const World = ({ backgroundColor = '#18183C' }) => {
  return (
    <div className="World" style={{ backgroundColor }}>
      <Suspense>
        <Canvas
          // flat={true}
          frameloop="demand"
          shadows
          camera={{ position: [0, 0, 5], fov: 65 }}
        >
          {/* <color transparent opacity={0.5} attach="background" args={['black']} /> */}
          {/* <Environment resolution={128} background={false} preset="forest" /> */}
          {/* <ambientLight intensity={0.1} /> */}
          <AsciiRenderer fgColor="#7436a3" bgColor="#18183C" />

          <spotLight position={[20, 20, 22]} angle={0.3} penumbra={1} />
          {/* <pointLight position={[-10, -10, -10]} /> */}
          {/* <spotLight
            intensity={1}
            position={[10, 10, 1.2]}
            angle={0.5}
            penumbra={1}
            shadow-mapSize={[1024, 1024]}
            castShadow
            shadow-normalBias={0.1}
            color={'#ffffff'}
          /> */}

          {/* <spotLight
            intensity={1.6}
            position={[-10, -5, 4]}
            angle={1}
            penumbra={0.5}
            shadow-normalBias={0.01}
            color={'#ffffff'}
          /> */}
          <group position={[0, 0, 0]} rotation={[Math.PI / 2, 0, 0]}>
            {/* <Caustics
              lightSource={[-1, 6, -1]}
              color="#fff0cb"
              scale={2}
              frames={Infinity}
              resolution={1024}
              ior={4}
              worldRadius={0.06}
              intensity={0.005}
              falloff={1}
            > */}
            <FlowerModel
              rotation={[0, 0.33, 0]}
              position={[-2.8, 0.2, 0.3]}
              scale={2.2}
              castShadow
            />
            {/* </Caustics> */}
            <mesh position={[0, -10, 0]} rotation={[Math.PI / 2, Math.PI, 0]}>
              <planeGeometry attach="geometry" args={[100, 100]} />
              <meshBasicMaterial
                attach="material"
                color={backgroundColor}
                transparent
                opacity={1}
              />
            </mesh>
          </group>
          {/* <EffectComposer smaa>
            <Bloom luminanceThreshold={0} luminanceSmoothing={0.4} opacity={2.5}></Bloom>
          </EffectComposer> */}
        </Canvas>
      </Suspense>
    </div>
  )
}

function AsciiRenderer({
  renderIndex = 1,
  bgColor = 'black',
  fgColor = 'white',
  characters = ' .:/Dariah',
  invert = true,
  color = false,
  resolution = 0.15,
}) {
  // Reactive state
  const { size, gl, scene, camera } = useThree()

  // Create effect
  const effect = useMemo(() => {
    const effect = new AsciiEffect(gl, characters, { invert, color, resolution })
    effect.domElement.style.position = 'absolute'
    effect.domElement.style.top = '0px'
    effect.domElement.style.left = '0px'
    effect.domElement.style.pointerEvents = 'none'
    return effect
  }, [characters, invert, color, resolution])

  // Styling
  useLayoutEffect(() => {
    effect.domElement.style.color = fgColor
    effect.domElement.style.backgroundColor = bgColor
  }, [fgColor, bgColor])

  // Append on mount, remove on unmount
  useEffect(() => {
    gl.domElement.style.opacity = '0'
    gl.domElement.parentNode.appendChild(effect.domElement)
    return () => {
      gl.domElement.style.opacity = '1'
      gl.domElement.parentNode.removeChild(effect.domElement)
    }
  }, [effect])

  // Set size
  useEffect(() => {
    effect.setSize(size.width, size.height)
  }, [effect, size])

  // Take over render-loop (that is what the index is for)
  useFrame((state) => {
    effect.render(scene, camera)
  }, renderIndex)

  // This component returns nothing, it is a purely logical
}

export default World
