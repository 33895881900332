import { AnalyticsCookiesAccepted, CookiesNotYetAccepted } from '../constants'
import { usePersistentStore } from '../store'

// Getting non-reactive fresh state on demand
let persistentState = usePersistentStore.getState()
console.debug('[analytics] check localStorage item:', import.meta.env.VITE_APP_NAME)
try {
  const localStorageState = JSON.parse(localStorage.getItem(import.meta.env.VITE_APP_NAME))
  if (localStorageState) {
    console.debug('[analytics] use localStorage item:', import.meta.env.VITE_APP_NAME)
    persistentState = localStorageState
  }
} catch (e) {
  console.warn(e)
}
export const AcceptAnalyticsCookies =
  persistentState.state?.acceptAnalyticsCookies || AnalyticsCookiesAccepted
export const AcceptCookies = persistentState.state?.acceptCookies || CookiesNotYetAccepted
