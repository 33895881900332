import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../utils/api'
import { useStore } from '../store'

const FetchCategories = ({ debug }) => {
  const setCategories = useStore((state) => state.setCategories)
  const { status, error, data } = useQuery({
    queryKey: ['FetchCategories'],
    queryFn: ({ signal }) => {
      return axiosInstance
        .get('/categories', {
          // Pass the signal to `axios`
          signal,
          // Pass the params to `axios`
          params: {
            per_page: 100,
          },
        })
        .then(({ data }) => {
          setCategories(data)
          return data
        })
    },
  })

  if (!debug) {
    return null
  }
  return (
    <div className="p-4 bg-primary">
      <h2>categories</h2>
      <p>Status: {status}</p>
      {status === 'error' && <p>Error: {error.message}</p>}
      {status === 'loading' && <p>...</p>}
      {status === 'success' && (
        <div>
          n. categories: <b>{data.length}</b>
          {data.map((cat) => (
            <div key={cat.id}>
              {cat.id} - {cat.name} <span>{cat.slug}</span>
              <pre>{JSON.stringify(cat, null, 2)}</pre>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default FetchCategories
