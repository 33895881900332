import './TeamMember.css'

const TeamMember = ({ person = null, fluid = false }) => {
  if (person === null) return null
  // extract the image url from the content
  const imageMatch = person?.content?.rendered?.match(/<img[^>]*src="([^"]*)"[^>]*>/)
  const imageUrl = imageMatch ? imageMatch[1] : null

  return (
    <div className="TeamMember">
      <div className="d-flex flex-row align-items-center">
        {typeof imageUrl === 'string' && imageUrl.length > 0 && (
          <figure>
            <img src={imageUrl} alt={person?.title?.rendered} style={{ maxHeight: 100 }} />
          </figure>
        )}
        <div className={typeof imageUrl === 'string' && imageUrl.length > 0 ? 'px-3' : ''}>
          <h3
            dangerouslySetInnerHTML={{
              __html: person?.title?.rendered,
            }}
          />
          {fluid && (
            <div
              dangerouslySetInnerHTML={{
                __html: person?.excerpt?.rendered,
              }}
            />
          )}
        </div>
      </div>
      {fluid ? (
        // remove the image from the content
        <div
          dangerouslySetInnerHTML={{
            __html: person?.content?.rendered
              .replace(/<figure[^>]*>.*?<\/figure>/g, '')
              .replace(/<img[^>]*>/g, ''),
          }}
        ></div>
      ) : (
        <div
          dangerouslySetInnerHTML={{
            __html: person?.excerpt?.rendered,
          }}
        />
      )}
    </div>
  )
}
export default TeamMember
