import { Container, Row, Col } from 'react-bootstrap'
import React, { useEffect } from 'react'
import Logo from './svg/Logo'
import LogoUniC2dh from './svg/LogoUniC2dh'
import ScrollToTopBottom from './svg/ScrollToButton'
import LogoBnl from './svg/LogoBnl'
import './Footer.css'
import '../pages/Home.css'
import TwitterLogo from './svg/TwitterLogo'
import EmailIcon from './svg/EmailIcon'
import { useCurrentLanguage } from '../utils/i18n'
import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../utils/api'
import LanguageSwitcher from './LanguageSwitcher'
import { useTranslation } from 'react-i18next'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { FooterRoutes, WpThemeDirPath } from '../constants'

const Email = import.meta.env.VITE_CONTACT_EMAIL || 'hello@dariah.lu'

const Footer = ({ addJoinUs = false }) => {
  const { t } = useTranslation()
  const currentYear = new Date().getFullYear()

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const language = useCurrentLanguage()
  // useQuery hook to load join us page content
  const { status, data } = useQuery({
    queryKey: ['join-us', language],
    queryFn: ({ signal }) => {
      return axiosInstance
        .get('/pages', {
          signal,
          params: {
            per_page: 1,
            slug: 'join-us-' + language,
          },
        })
        .then(({ data }) => (data.length > 0 ? data[0] : null))
    },
    enabled: addJoinUs,
  })

  console.debug('[Footer] join-us-' + language + ' page: \n status:', status)

  return (
    <footer className="Footer bg-primary">
      <section className="border-tertiary text-tertiary rounded">
        <Container fluid className="m-0">
          <ScrollToTopBottom
            onClick={scrollToTop}
            className="ScrollToTop"
            id="ScrollToTop"
            title="Go to top"
          />
          <Row>
            <Col md={6} sm={12}>
              <ul>
                {FooterRoutes.map((route) => (
                  <li key={route.to}>
                    <NavLink key={route.to} to={route.to} className="Footer_link">
                      {t(route.name)}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </Col>
            {addJoinUs && (
              <Col md={6} sm={12}>
                <h2
                  dangerouslySetInnerHTML={{
                    __html: data?.title?.rendered || 'Join us',
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.content?.rendered || '',
                  }}
                />
                <Link to={'/page/consortium'} className="btn btn-primary my-4">
                  {t('joinDariah')} <span className="ms-2">&rarr;</span>
                </Link>
              </Col>
            )}
            <Col md={6} sm={12}>
              <h2>{t('contact')}</h2>

              <div className="d-flex mb-4">
                <TwitterLogo />
                <a href={`mailto:${Email}`}>
                  <EmailIcon email={Email} />
                </a>
              </div>

              <LanguageSwitcher />
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <div
              className="Footer_logos d-flex flex-wrap justify-content-md-around align-items-center mb-3"
              target="_blank"
            >
              <a className="mx-2" href="/">
                <Logo
                  width={140}
                  color="var(--tertiary-color)"
                  ignoreStore
                  accentColor="var(--accent-color)"
                />
              </a>

              <div className="mx-2">
                <LogoUniC2dh width={180} />
              </div>
              <div className="mx-2">
                <LogoBnl />
              </div>
              <a className="mx-2" href="https://anlux.public.lu/fr.html">
                <img
                  alt="le gouvernement du grand-duché de luxembourg Archives nationales"
                  width="220px"
                  src={WpThemeDirPath + 'GOUV_Archives.png'}
                />
              </a>
            </div>
          </Row>
          <Row>
            <span className="copyright text-md-center ">
              Copyright ©{' '}
              <a href="https://wwwen.uni.lu/" target="_blank">
                Université du Luxembourg
              </a>{' '}
              {currentYear}. All rights reserved
            </span>
          </Row>
        </Container>
      </section>
    </footer>
  )
}

export default Footer
