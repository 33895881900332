import { useState, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { animated, useSpring, config } from '@react-spring/web'
import { usePersistentStore, useStore } from '../store'
import './CConsent.css'
import { AnalyticsCookiesAccepted, AnalyticsCookiesRejected, CookiesAccepted } from '../constants'

const CConsent = () => {
  const [styles, api] = useSpring(() => ({
    y: 500,
    config: config.gentle,
    delay: 2500,
  }))

  const [acceptCookies, setAcceptCookies] = usePersistentStore((state) => [
    state.acceptCookies,
    state.setAcceptCookies,
  ])

  const [acceptAnalyticsCookies, setAcceptAnalyticsCookies] = usePersistentStore((state) => [
    state.acceptAnalyticsCookies,
    state.setAcceptAnalyticsCookies,
  ])

  const { t } = useTranslation()

  const toggleAcceptAnalytics = () => {
    if (acceptAnalyticsCookies === AnalyticsCookiesRejected) {
      setAcceptAnalyticsCookies(AnalyticsCookiesAccepted)
    } else {
      setAcceptAnalytics(AnalyticsCookiesRejected)
    }
  }

  useLayoutEffect(() => {
    console.debug('@useLayoutEffect [CConsent] acceptCookies', acceptCookies)
    if (acceptCookies === CookiesAccepted) {
      api.start({
        y: 500,
        delay: 500,
        onRest: () => {
          console.info('@useLayoutEffect [CConsent] set cookies accepted.')
          setAcceptCookies(CookiesAccepted)
        },
      })
    } else {
      console.info('@useLayoutEffect [CConsent] vai vai')
      api.start({
        y: 0,
        delay: 2500,
      })
    }
  }, [acceptCookies])

  return (
    <animated.div
      className="CConsent px-4 py-3 py-md-0 d-flex align-items-center flex-column flex-md-row justify-content-between"
      style={styles}
    >
      <p dangerouslySetInnerHTML={{ __html: t('textCookieConsent') }} />
      <button className="ms-4 btn btn-secondary" onClick={setAcceptCookies}>
        accept
      </button>
    </animated.div>
  )
}

export default CConsent
