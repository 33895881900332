import React, { Suspense, lazy, useCallback, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import FetchCategories from './components/FetchCategories'
import World from './components/World'
import Footer from './components/Footer'
import './utils/i18n'
import './App.css'
import Header from './components/Header'
import { useWindowStore } from './store'
import { debounce } from './utils/common'
import {
  NewsEventsCategories,
  ResourceCategories,
  NewsEventsRoute,
  ResourcesRoute,
  PostRoute,
  AboutRoute,
  ContactUsRoute,
  JoinUsRoute,
  ConsortiumRoute,
  TermsOfUseRoute,
  AnalyticsCookiesAccepted,
  CookiesNotYetAccepted,
} from './constants'
import { isMobile } from 'react-device-detect'
import MobileHeader from './components/MobileHeader'
import { AcceptCookies, AcceptAnalyticsCookies } from './utils/analytics'

const queryClient = new QueryClient()

// const Home = React.lazy(() => import('./pages/Home'))
// const Category = React.lazy(() => import('./pages/Category'))
// const Post = React.lazy(() => import('./pages/Post'))
// const Page = React.lazy(() => import('./pages/Page'))

import Home from './pages/Home'
import Category from './pages/Category'
import Post from './pages/Post'
import Page from './pages/Page'
import About from './pages/About'
import PageWithHeadings from './pages/PageWithHeadings'
import LocationWatcher from './components/LocationWatcher'
import CConsent from './components/CConsent'

const GoogleAnalytics = lazy(() => import('./components/GoogleAnalytics'))

function App() {
  const updateDimensions = useWindowStore((state) => state.updateDimensions)
  const change = () => {
    console.debug('[app] change')
    updateDimensions()
  }
  const debouncedHandleResize = useCallback(debounce(change, 1000), [])
  useEffect(() => {
    console.debug('[app] useEffect @resize')
    window.addEventListener('resize', debouncedHandleResize)
    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [])

  console.info(
    '[App]',
    '\n - AcceptCookies:',
    AcceptCookies,
    '\n - AcceptAnalyticsCookies:',
    AcceptAnalyticsCookies,
  )
  return (
    <QueryClientProvider client={queryClient}>
      <LocationWatcher />
      {AcceptAnalyticsCookies === AnalyticsCookiesAccepted && (
        <Suspense fallback={null}>
          <GoogleAnalytics />
        </Suspense>
      )}
      {AcceptCookies === CookiesNotYetAccepted && <CConsent />}
      <World />
      <div className="App w-100">
        {isMobile ? <MobileHeader /> : <Header className="pe-5" />}
        <Routes>
          <Route
            path={ResourcesRoute.path}
            element={
              <Suspense>
                <Category
                  tabs={ResourceCategories}
                  categoryRoute={ResourcesRoute}
                  titleLabel={'latestResources'}
                  params={{
                    per_page: 1,
                    categories: [import.meta.env.VITE_WP_RESOURCES_CATEGORY_ID],
                    order: 'desc',
                    orderby: 'date',
                  }}
                  defaultTab={ResourceCategories[0]}
                />
              </Suspense>
            }
          />
          <Route
            path={NewsEventsRoute.path}
            element={
              <Suspense>
                <Category
                  tabs={NewsEventsCategories}
                  categoryRoute={NewsEventsRoute}
                  titleLabel={'latestNewsEvents'}
                  hideCover
                  params={{
                    per_page: 1,
                    categories: [import.meta.env.VITE_WP_NEWS_EVENTS_CATEGORY_ID],
                    order: 'desc',
                    orderby: 'date',
                  }}
                  defaultTab={NewsEventsCategories[0]}
                />
              </Suspense>
            }
          />
          <Route
            path={PostRoute.path}
            element={
              <Suspense>
                <Post />
              </Suspense>
            }
          />
          <Route
            path={AboutRoute.path}
            element={
              <Suspense>
                <About slug={'about'} />
              </Suspense>
            }
          />
          <Route
            path={ContactUsRoute.path}
            element={
              <Suspense>
                <Page slug={'contact-us'} />
              </Suspense>
            }
          />
          <Route
            path={ConsortiumRoute.path}
            element={
              <Suspense>
                <PageWithHeadings />
              </Suspense>
            }
          />
          <Route
            path={TermsOfUseRoute.path}
            element={
              <Suspense>
                <Page slug={'terms-of-use'} otherParagraphsInHublot />
              </Suspense>
            }
          />
          <Route
            path={JoinUsRoute.path}
            element={
              <Suspense>
                <Page slug={'join-us'} otherParagraphsInHublot />
              </Suspense>
            }
          />
          <Route
            path="/"
            element={
              <Suspense>
                <Home></Home>
              </Suspense>
            }
          />
          <Route
            path="/page/:slug"
            element={
              <Suspense>
                <Page></Page>
              </Suspense>
            }
          />
        </Routes>
        <Footer></Footer>
        <FetchCategories />
      </div>
    </QueryClientProvider>
  )
}

export default App
