import { Container, Row, Col } from 'react-bootstrap'
import './Home.css'
import { useWindowStore } from '../store'
import ScrollToButton from '../components/svg/ScrollToButton'
import React, { useEffect, useState } from 'react'
import { useCurrentLanguage } from '../utils/i18n'
import { axiosInstance } from '../utils/api'
import { useQuery } from '@tanstack/react-query'
import PostListItem from '../components/PostListItem'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { AboutRoute, BootstrapColumnLayout, PageRoute } from '../constants'
import TeamMember from '../components/TeamMember'

function Home() {
  const { t } = useTranslation()

  const scrollToNextSection = () => {
    const element = document.getElementById('down-section')
    if (!element) return
    const top = element.getBoundingClientRect().top
    window.scrollTo({
      top,
      behavior: 'smooth',
    })
  }

  const height = useWindowStore((state) => state.height)
  // load team and mission, by language
  const language = useCurrentLanguage()
  const slugs = ['home', 'about', 'mission', 'team'].map((slug) => [slug, language].join('-'))

  // load latest news
  const { status: newsStatus, data: posts } = useQuery({
    queryKey: ['news', language],
    queryFn: ({ signal }) => {
      return axiosInstance

        .get('/posts', {
          signal,
          params: {
            per_page: 1,
          },
        })
        .then(({ data }) => data)
    },
  })

  // with UseQuery hook, fetch data from api using the slugs with language suffix
  const { status, data } = useQuery({
    queryKey: ['home', language],
    queryFn: ({ signal }) => {
      return axiosInstance
        .get('/pages', {
          signal,
          params: {
            per_page: slugs.length,
            slug: slugs,
          },
        })
        .then(({ data }) => data.reduce((acc, page) => ({ ...acc, [page.slug]: page }), {}))
    },
    enabled: newsStatus === 'success',
  })

  // load latest news
  const { status: peopleStatus, data: people } = useQuery({
    queryKey: ['people', language],
    queryFn: ({ signal }) => {
      return axiosInstance
        .get('/posts', {
          signal,
          params: {
            categories: [import.meta.env.VITE_WP_PEOPLE_CATEGORY_ID],
            per_page: 30,
          },
        })
        .then(({ data }) => data.filter((post) => post.slug.indexOf(`-${language}`) !== -1))
    },
    enabled: status === 'success',
  })
  return (
    <div className="Home">
      <section className="hublot" style={{ minHeight: height / 2 }}>
        <Container style={{ paddingTop: 100 }}>
          <Row>
            <Col {...BootstrapColumnLayout}>
              <h1
                dangerouslySetInnerHTML={{
                  __html: data?.['home-' + language]?.content?.rendered
                    .replace('<p>', '')
                    .replace('</p>', ''),
                }}
              ></h1>
              <h2
                className="text-primary mt-5"
                dangerouslySetInnerHTML={{
                  __html: t('latestPosts'),
                }}
              />
              {newsStatus === 'error' && <p>Error: {newsStatus.message}</p>}
              <ol className="p-0 my-4">
                {newsStatus === 'success' &&
                  posts.map((post) => (
                    <li key={post.id}>
                      <PostListItem key={post.id} {...post} />
                    </li>
                  ))}
              </ol>
            </Col>
          </Row>
        </Container>
        <ScrollToButton
          className="ScrollToBottom"
          strokeColor="var(--primary-color)"
          onClick={scrollToNextSection}
          style={{ height: 40, right: 45, top: height - 105 }}
        />
      </section>
      <section className="hublot-blind" id="down-section">
        {/* about us and mission */}
        <Container fluid className="px-0 position-relative">
          <Row>
            <Col lg={6} sm={12} style={{ minHeight: 200 }}>
              <div className="border-tertiary h-100 text-tertiary rounded p-4">
                {data?.['about-' + language]?.content?.rendered ? (
                  <>
                    <h2
                      className="px-3"
                      dangerouslySetInnerHTML={{
                        __html: data?.['about-' + language]?.title?.rendered,
                      }}
                    />

                    <div
                      className="mt-3 p-3"
                      dangerouslySetInnerHTML={{
                        __html: data?.['about-' + language]?.excerpt?.rendered.replace(
                          /<a[^>]*>Continued*<\/a>/,
                          '',
                        ),
                      }}
                    />
                    <Link className="btn btn-primary  mx-3" to={AboutRoute.to}>
                      {t('readMore')} <span className="ms-2">&rarr;</span>
                    </Link>
                  </>
                ) : null}
              </div>
            </Col>
            <Col lg={6} sm={12} className="mt-4 mt-sm-4 mt-lg-0" style={{ minHeight: 200 }}>
              <div className="bg-tertiary rounded h-100 border-tertiary p-4 text-primary">
                {data?.['mission-' + language]?.content?.rendered ? (
                  <>
                    <h2
                      className="px-3"
                      dangerouslySetInnerHTML={{
                        __html: data?.['mission-' + language]?.title?.rendered,
                      }}
                    />
                    <div
                      className="mt-3 p-3"
                      dangerouslySetInnerHTML={{
                        __html: data?.['mission-' + language]?.content?.rendered,
                      }}
                    />
                    <Link
                      className="btn btn-secondary  mx-3"
                      to={PageRoute.to.replace(':slug', 'consortium')}
                    >
                      {t('readMore')} <span className="ms-2">&rarr;</span>
                    </Link>
                  </>
                ) : null}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="hublot" style={{ minHeight: height / 4 }}>
        {/* team */}
        <Container fluid className="px-0 pt-2 pb-5">
          <Row>
            <h2 className="text-primary mb-4">{t('meetTheTeam')}</h2>
          </Row>
          <Row>
            {people
              ?.sort((a, b) => {
                return a.slug > b.slug ? -1 : 1
              })
              .map((person) => (
                <Col className="mb-3" key={person.id} md={{ span: 6 }} lg={{ span: 4 }}>
                  <TeamMember person={person} />
                </Col>
              ))}
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default Home
