/**
 * This component is a navbar wrapped inside a bootstrap container
 */
import { Container, Navbar, Nav } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import './Header.css'
import Logo from './svg/Logo'
import { HeaderRoutes } from '../constants'
import { Link, NavLink } from 'react-router-dom'
import { useWindowStore } from '../store'

const Header = (props) => {
  const { t } = useTranslation()
  // listen to dimensions
  const width = useWindowStore((state) => state.width)
  return (
    <header className="Header">
      <Container fluid {...props}>
        <Navbar>
          <Navbar.Brand>
            <Link to="/">
              <Logo hideName={width < 768} />
            </Link>
          </Navbar.Brand>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              {HeaderRoutes.map((route, i) => (
                <NavLink title={t(route.name)} className="ms-3" key={i} to={route.to}>
                  {t(route.name)}
                </NavLink>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  )
}
export default Header
