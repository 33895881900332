import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../utils/api'
import { Col, Container, Row } from 'react-bootstrap'
import { useCurrentLanguage } from '../utils/i18n'
import './Page.css'
import { useLayoutEffect } from 'react'

const PageWithHeadings = ({ level = 'h3', slug = 'consortium', children }) => {
  const language = useCurrentLanguage()
  const safeSlug = [slug.replace(/[^a-z0-9-]/gi, ''), language].join('-')

  const { status, data } = useQuery({
    queryKey: ['page-', safeSlug, language],
    queryFn: ({ signal }) => {
      return axiosInstance
        .get('/pages', {
          signal,
          params: {
            per_page: 1,
            slug: safeSlug,
          },
        })
        .then(({ data }) => (data.length ? data[0] : null))
    },
  })
  console.debug('[Page] slug:', safeSlug, status, data, language)

  const contents = data?.content?.rendered || ''
  const firstParagraph = contents.split('</p>')[0]
  const partners = contents
    .split('</p>')
    .slice(1)
    .join('</p>')
    .split('<' + level)
    .slice(1)
    .map((d) => '<' + level + d)

  useLayoutEffect(() => {
    status === 'success' &&
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
  }, [status])
  // table of contents
  return (
    <div className="Page">
      <section className="hublot-band"></section>
      <section className="bg-primary py-5"></section>
      <section className="hublot-blind my-0">
        {' '}
        <Container>
          <Row className="py-5">
            <Col lg={{ span: 5 }}>
              <h1
                dangerouslySetInnerHTML={{
                  __html: data?.title?.rendered,
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 8 }}>
              <div
                className="Post_firstParagraph"
                dangerouslySetInnerHTML={{ __html: firstParagraph }}
              />
            </Col>
          </Row>
          {partners.map((partner, index) => {
            const images = partner.match(/<img[^>]*>/g)
            const heading = partner.split('</' + level + '>')[0].replace(/<[^>]*>/g, '')
            const contentWithoutImages = partner
              .split('</' + level + '>', 2)
              .pop()
              .replace(/<img[^>]*>/g, '')
            const hasImages = images?.length > 0
            return (
              <Row key={index} className="Consortium_partner my-5">
                <Col md={{ span: 12 }}>
                  <h3 className="mb-5" dangerouslySetInnerHTML={{ __html: heading }} />
                </Col>
                <Col lg={{ span: hasImages ? 6 : 9 }}>
                  <div dangerouslySetInnerHTML={{ __html: contentWithoutImages }} />
                </Col>

                {hasImages && (
                  <Col lg={{ span: 6 }}>
                    {images?.map((image, index) => (
                      <div
                        className="Page_img"
                        key={index}
                        dangerouslySetInnerHTML={{ __html: image }}
                      />
                    ))}
                  </Col>
                )}
              </Row>
            )
          })}
        </Container>
      </section>
      <section className="hublot py-5 text-primary" style={{ minHeight: window.innerHeight / 3 }}>
        {children}
      </section>
    </div>
  )
}

export default PageWithHeadings
