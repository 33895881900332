const ThreeDotsIcon = ({ color = 'var(--primary-color)', width = 20 }) => {
  return (
    <div>
      <svg
        width={width}
        height={width}
        data-name="Three Dots Icon"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.6875 10.2732C12.6875 10.7058 12.5592 11.1288 12.3188 11.4885C12.0785 11.8482 11.7368 12.1286 11.3371 12.2942C10.9374 12.4597 10.4976 12.5031 10.0732 12.4187C9.64891 12.3343 9.25913 12.1259 8.95321 11.82C8.64728 11.5141 8.43894 11.1243 8.35453 10.7C8.27013 10.2756 8.31345 9.83579 8.47901 9.43608C8.64458 9.03636 8.92496 8.69472 9.28469 8.45436C9.64442 8.21399 10.0674 8.0857 10.5 8.0857C11.0802 8.0857 11.6366 8.31616 12.0468 8.7264C12.457 9.13664 12.6875 9.69303 12.6875 10.2732ZM10.5 6.21069C10.9326 6.21069 11.3556 6.0824 11.7153 5.84203C12.075 5.60167 12.3554 5.26003 12.521 4.86031C12.6866 4.4606 12.7299 4.02077 12.6455 3.59643C12.5611 3.1721 12.3527 2.78233 12.0468 2.4764C11.7409 2.17047 11.3511 1.96213 10.9268 1.87773C10.5024 1.79332 10.0626 1.83664 9.66288 2.00221C9.26317 2.16777 8.92153 2.44815 8.68116 2.80788C8.4408 3.16762 8.3125 3.59055 8.3125 4.02319C8.3125 4.60336 8.54297 5.15975 8.95321 5.56999C9.36344 5.98023 9.91984 6.21069 10.5 6.21069ZM10.5 14.3357C10.0674 14.3357 9.64442 14.464 9.28469 14.7044C8.92496 14.9447 8.64458 15.2864 8.47901 15.6861C8.31345 16.0858 8.27013 16.5256 8.35453 16.95C8.43894 17.3743 8.64728 17.7641 8.95321 18.07C9.25913 18.3759 9.64891 18.5843 10.0732 18.6687C10.4976 18.7531 10.9374 18.7097 11.3371 18.5442C11.7368 18.3786 12.0785 18.0982 12.3188 17.7385C12.5592 17.3788 12.6875 16.9558 12.6875 16.5232C12.6875 15.943 12.457 15.3866 12.0468 14.9764C11.6366 14.5662 11.0802 14.3357 10.5 14.3357Z"
          fill={color}
        />
      </svg>
    </div>
  )
}

export default ThreeDotsIcon
