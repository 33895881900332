import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

import { BrowserRouter } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'

console.log(`

██████╗  █████╗ ██████╗ ██╗ █████╗ ██╗  ██╗   ██╗     ██╗   ██╗
██╔══██╗██╔══██╗██╔══██╗██║██╔══██╗██║  ██║   ██║     ██║   ██║
██║  ██║███████║██████╔╝██║███████║███████║   ██║     ██║   ██║
██║  ██║██╔══██║██╔══██╗██║██╔══██║██╔══██║   ██║     ██║   ██║
██████╔╝██║  ██║██║  ██║██║██║  ██║██║  ██║██╗███████╗╚██████╔╝
╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝╚══════╝ ╚═════╝ 

`)

console.info(
  '[main]',
  '\n - wp api url:',
  import.meta.env.VITE_WP_API_URL,
  '\n - git tag:',
  import.meta.env.VITE_APP_GIT_TAG,
  '\n - git commit:',
  import.meta.env.VITE_APP_GIT_COMMIT,
  '\n - git branch:',
  import.meta.env.VITE_APP_GIT_BRANCH,
  '\n - git remote:',
  import.meta.env.VITE_APP_GIT_REMOTE,
  '\n - React version:',
  React.version,
)

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
)
setTimeout(() => {
  document.getElementById('loading').classList.add('hide')
}, 100)
