import './FooterIcon.css'

const Email = ({ color = 'var(--primary-color)', width = 24 }) => {
  return (
    <i className="FooterIcon my-3">
      <svg
        width={width}
        height={width}
        data-name="Email Logo"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28 6.14404H4C3.73478 6.14404 3.48043 6.2494 3.29289 6.43694C3.10536 6.62447 3 6.87883 3 7.14404V24.144C3 24.6745 3.21071 25.1832 3.58579 25.5583C3.96086 25.9333 4.46957 26.144 5 26.144H27C27.5304 26.144 28.0391 25.9333 28.4142 25.5583C28.7893 25.1832 29 24.6745 29 24.144V7.14404C29 6.87883 28.8946 6.62447 28.7071 6.43694C28.5196 6.2494 28.2652 6.14404 28 6.14404ZM16 16.7878L6.57125 8.14404H25.4287L16 16.7878ZM12.3387 16.144L5 22.8703V9.41779L12.3387 16.144ZM13.8188 17.5003L15.3188 18.8815C15.5032 19.0509 15.7446 19.1449 15.995 19.1449C16.2454 19.1449 16.4868 19.0509 16.6712 18.8815L18.1712 17.5003L25.4212 24.144H6.57125L13.8188 17.5003ZM19.6612 16.144L27 9.41654V22.8715L19.6612 16.144Z"
          fill={color}
        />
      </svg>
    </i>
  )
}

export default Email
