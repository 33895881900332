export const DefaultPastelColor = '#333357'
export const PastelColors = [
  '#76935b',
  '#3a6d6c',
  '#a66d42',
  '#c85b80',
  '#4B0082',
  '#8b28b5',
  '#67449c',
  '#333357',
  '#14363e',
  '#2c5c62',
  '#9d4053',
]

export const HomeRoute = { to: '/', name: 'HomeRoute' }
export const AboutRoute = { to: '/about', path: '/about', name: 'AboutRoute' }
export const NewsEventsRoute = { to: '/news', name: 'NewsEventsRoute', path: '/news/:category?' }
export const ResourcesRoute = {
  to: '/resources',
  name: 'ResourcesRoute',
  path: '/resources/:category?',
}
export const PageRoute = { to: '/page/:slug', path: '/page/:slug', name: 'PageRoute' }
export const PostRoute = { to: '/post/:slug', path: '/post/:slug', name: 'PostRoute' }
export const ContactUsRoute = { to: '/contact-us', path: '/contact-us', name: 'ContactUsRoute' }
export const ConsortiumRoute = {
  to: '/page/consortium',
  path: '/page/consortium',
  name: 'ConsortiumRoute',
}

export const JoinUsRoute = {
  to: '/page/join-us',
  path: '/page/join-us',
  name: 'JoinUsRoute',
}

export const TermsOfUseRoute = {
  to: '/page/terms-of-use',
  path: '/page/terms-of-use',
  name: 'TermsOfUseRoute',
}

export const ResourceCategories = [
  {
    id: import.meta.env.VITE_WP_RESOURCES_CATEGORY_ID,
    slug: 'resources',
  },
  {
    id: import.meta.env.VITE_WP_PROJECTS_CATEGORY_ID,
    slug: 'projects-training-material',
  },
  {
    id: import.meta.env.VITE_WP_TOOLS_CATEGORY_ID,
    slug: 'tools-workflows',
  },
]

export const NewsEventsCategories = [
  {
    id: import.meta.env.VITE_WP_NEWS_EVENTS_CATEGORY_ID,
    slug: 'news-events',
  },
  {
    id: import.meta.env.VITE_WP_NEWS_CATEGORY_ID,
    slug: 'news',
  },
  {
    id: import.meta.env.VITE_WP_EVENTS_CATEGORY_ID,
    slug: 'events',
  },
]

export const MainCategoriesIds = []
  .concat(
    ResourceCategories.map((category) => category.id),
    NewsEventsCategories.map((category) => category.id),
  )
  .map((id) => parseInt(id, 10))

export const HeaderRoutes = [
  HomeRoute,
  AboutRoute,
  // NewsEventsRoute,
  ResourcesRoute,
  ConsortiumRoute,
  JoinUsRoute,
]

export const FooterRoutes = [
  HomeRoute,
  AboutRoute,
  ResourcesRoute,
  ConsortiumRoute,
  JoinUsRoute,
  TermsOfUseRoute,
]
export const AllRoutes = [
  HomeRoute,
  AboutRoute,
  NewsEventsRoute,
  ResourcesRoute,

  ContactUsRoute,
  PageRoute,
  PostRoute,
]

export const AnalyticsCookiesAccepted = 'yes'
export const AnalyticsCookiesRejected = 'no'
export const CookiesAccepted = 'accepted' // technical cookies only
export const CookiesNotYetAccepted = 'not-yet-accepted'

export const BootstrapColumnLayout = {
  md: { span: 10, offset: 2 },
  //  md={{ span: 10, offset: 2 }} lg={{ span: 9, offset: 3 }}>
  lg: { span: 9, offset: 3 },
}

export const WpThemeDirPath = import.meta.env.PROD
  ? import.meta.env.VITE_WP_THEME_DIR || '/wp-content/themes/dariah-lu/'
  : '/'
