/**
 * A React component that renders a list of post categories.
 *
 * @module components/PostCategories
 * @requires react-router-dom
 * @requires ../store
 * @requires ../constants
 *
 * @param {Array} ids - An array of category IDs to include in the list.
 * @param {function} to - A function that returns the URL for a category link. Defaults to `DefaultTo`.
 * @param {function} rule - A function that determines whether to include a category in the list. Defaults to `DefaultRule`.
 * @param {String} className - Additional className, default is empty.
 * @param {Object} props - Additional props to pass to the `ol` element.
 *
 * @return {JSX.Element|null} The rendered list of categories, or null if no categories are provided or available.
 */
import { Link } from 'react-router-dom'
import { useStore } from '../store'
import {
  ResourceCategories,
  NewsEventsCategories,
  ResourcesRoute,
  NewsEventsRoute,
  MainCategoriesIds,
} from '../constants'
import './PostCategories.css'

const DefaultTo = (c) => {
  if (ResourceCategories.find((d) => d.id === c.id)) {
    return [ResourcesRoute.to, c.slug].join('/')
  }
  if (NewsEventsCategories.map((c) => c.id).includes(c.id)) {
    return [NewsEventsRoute.to, c.slug].join('/')
  }
  return `/category/${c.slug}`
}

const DefaultRule = (cat) => MainCategoriesIds.includes(cat.id)

const PostCategories = ({
  ids = [],
  to = DefaultTo,
  rule = DefaultRule,
  className = '',
  ...props
}) => {
  const categoriesMap = useStore((state) => state.categoriesMap)
  // If no category IDs are provided, or if the categories map is empty, return null.
  if (ids.length === 0 || Object.keys(categoriesMap).length === 0) {
    return null
  }
  // Filter the categories based on the provided rule function.
  const categories = ids.map((id) => categoriesMap[id]).filter(rule)
  return (
    <ol className={`PostCategories ${className} `} {...props}>
      {categories.map((c) => (
        <li className={`category-${c.slug}`} key={c.id}>
          <Link dangerouslySetInnerHTML={{ __html: c.name }} to={to(c)}></Link>
        </li>
      ))}
    </ol>
  )
}

export default PostCategories
