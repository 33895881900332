import './FooterIcon.css'

const TwitterLogo = ({ color = 'var(--primary-color)', width = 24 }) => {
  return (
    <a href={import.meta.env.VITE_TWITTER_URL} target="blank">
      <i className="FooterIcon me-2 my-3">
        <svg
          width={width}
          height={width}
          data-name="Twitter Logo"
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M30.9897 9.85162L27.2522 13.5891C26.4972 22.3416 19.116 29.1441 10.2822 29.1441C8.46723 29.1441 6.97098 28.8566 5.83473 28.2891C4.91848 27.8304 4.54348 27.3391 4.44973 27.1991C4.36613 27.0738 4.31195 26.9311 4.29123 26.7819C4.2705 26.6326 4.28378 26.4806 4.33005 26.3372C4.37633 26.1938 4.45441 26.0627 4.55847 25.9538C4.66253 25.8448 4.78987 25.7607 4.93098 25.7079C4.96348 25.6954 7.96098 24.5441 9.86473 22.3529C8.80896 21.4848 7.88732 20.4655 7.12973 19.3279C5.57973 17.0266 3.84473 13.0291 4.37973 7.05537C4.39669 6.86555 4.46753 6.68453 4.58392 6.53363C4.70031 6.38272 4.85739 6.26822 5.03668 6.2036C5.21596 6.13898 5.40997 6.12693 5.59587 6.16888C5.78177 6.21083 5.95182 6.30502 6.08598 6.44037C6.12973 6.48412 10.246 10.5779 15.2785 11.9054V11.1441C15.2766 10.3459 15.4344 9.55529 15.7427 8.81896C16.0509 8.08263 16.5034 7.41542 17.0735 6.85662C17.6271 6.30378 18.2858 5.86742 19.0108 5.5733C19.7358 5.27918 20.5124 5.13325 21.2947 5.14412C22.3442 5.15447 23.3731 5.43619 24.2814 5.96188C25.1898 6.48757 25.9467 7.23934 26.4785 8.14412H30.2822C30.4801 8.14396 30.6736 8.20253 30.8382 8.3124C31.0028 8.42227 31.1311 8.57851 31.2069 8.76133C31.2826 8.94415 31.3024 9.14534 31.2638 9.33943C31.2251 9.53351 31.1297 9.71177 30.9897 9.85162Z"
            fill={color}
          />
        </svg>
      </i>
    </a>
  )
}

export default TwitterLogo
