import { NavLink } from 'react-router-dom'
import { HeaderRoutes } from '../constants'
import { useTranslation } from 'react-i18next'
import { Nav } from 'react-bootstrap'
import './MobileMenu.css'
import TwitterLogo from './svg/TwitterLogo'
import InstagramLogo from './svg/InstagramLogo'
import LinkedInLogo from './svg/LinkedInLogo'

const MobileMenu = ({ handleClick }) => {
  const { t } = useTranslation()

  return (
    <div className="MobileMenu">
      <div className="MobileMenuInner">
        <Nav>
          {HeaderRoutes.map((route, i) => (
            <NavLink onClick={handleClick} className="ms-3" key={i} to={route.to}>
              {t(route.name)}
            </NavLink>
          ))}
        </Nav>
        <div className="MobileMenuSocialIcons">
          <TwitterLogo color={'var(--accent-color)'} />
          <InstagramLogo color={'var(--accent-color)'} />
          <LinkedInLogo color={'var(--accent-color)'} />
        </div>
      </div>
    </div>
  )
}

export default MobileMenu
