import { Col, Container, Nav, Row, Dropdown, DropdownButton, Navbar } from 'react-bootstrap'
import './ListOfFilterableItems.css'
import { Link } from 'react-router-dom'
import { axiosInstance } from '../utils/api'
import { useQuery } from '@tanstack/react-query'
import PostListItem from './PostListItem'
import { isMobile } from 'react-device-detect'
import ThreeDotsIcon from './svg/ThreeDotsIcon'

const ListOfFilterableItems = ({
  title = 'Title',
  excerpt = 'Short excerpt',
  tabs = [{ name: 'all', label: 'All', to: '/all' }],
  enabled = false,
  className = '',
  url,
  params = {},
  children,
  ...props
}) => {
  const { status, error, data } = useQuery({
    queryKey: ['ListOfFilterableItems', url, params],
    queryFn: ({ signal }) => {
      return axiosInstance.get(url, { signal, params }).then(({ data }) => data)
    },
    enabled: enabled && !!url,
  })
  const activeTab = tabs.find((tab) => tab.isActive)
  return (
    <div {...props} className={`ListOfFilterableItems hublot ${className}`}>
      {isMobile ? (
        <Nav className="align-items-center justify-content-space-end">
          <Navbar.Text>{activeTab.label}</Navbar.Text>
          <DropdownButton
            id="dropdown-basic-button"
            title={<ThreeDotsIcon />}
            className="my-dropdown-button"
          >
            {tabs.map((tab, i) => (
              <Dropdown.Item key={i}>
                <Link
                  className={`nav-link ${tab.isActive ? 'active' : ''} mx-1`}
                  key={i}
                  to={tab.to}
                  eventkey={tab.slug}
                >
                  {tab.label}
                </Link>
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </Nav>
      ) : (
        <Nav className="align-items-center">
          {tabs.map((tab, i) => (
            <Link
              className={`nav-link ${tab.isActive ? 'active' : ''} mx-1`}
              key={i}
              to={tab.to}
              eventkey={tab.slug}
            >
              {tab.label}
            </Link>
          ))}
        </Nav>
      )}
      <div className="position-absolute left-0 curved-corner-top-left"></div>
      <div className="position-absolute right-0 curved-corner-top-right"></div>
      {children}
      <Container className="p-2 p-lg-5">
        {/* <p>Status: {status}</p> */}
        {status === 'error' && <p>Error: {error.message}</p>}
        {status === 'loading' && <p>...</p>}
        {status === 'success' && (
          <Row>
            {/* n. items: <b>{data.length}</b> */}
            {data.map((item) => (
              <Col lg={6} key={item.id}>
                <PostListItem hideCover {...item} />
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </div>
  )
}
export default ListOfFilterableItems
