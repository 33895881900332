const ArrowSign = ({
  onClick,
  fillColor = 'var(--primary-color)',
  width = 15,
  className = 'Arrow',
  ...props
}) => {
  const ratio = 25 / 16
  const height = width / ratio
  return (
    <div className={className} {...props}>
      <svg
        width={width}
        height={height}
        data-name="Arrow"
        viewBox="0 0 25 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.661 1.10837L24.025 7.58989C24.4155 7.98763 24.4155 8.63249 24.025 9.03023L17.661 15.5118C17.2705 15.9095 16.6373 15.9095 16.2468 15.5118C15.8563 15.114 15.8563 14.4692 16.2468 14.0714L20.9037 9.32854L0.31787 9.32853L0.31787 7.29159L20.9037 7.29159L16.2468 2.5487C15.8563 2.15097 15.8563 1.5061 16.2468 1.10837C16.6373 0.710627 17.2705 0.710627 17.661 1.10837Z"
          fill={fillColor}
        />
      </svg>
    </div>
  )
}

export default ArrowSign
