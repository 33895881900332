import { useStore } from '../../store'

const Logo = ({
  color = 'var(--primary-color)',
  accentColor = 'var(--accent-color)',
  width = 200,
  ignoreStore = false,
  hideName = false,
}) => {
  const logoFillColor = useStore((state) => state.logoFillColor)
  const fillColor = ignoreStore ? color : logoFillColor
  const ratio = 508 / 155
  const height = width / ratio

  return (
    <div className="Logo" style={{ width: hideName ? 80 : width, overflow: 'hidden' }}>
      <svg
        width={width}
        height={height}
        data-name="Logo Dariah lu"
        viewBox="0 0 508 155"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2_91)">
          {!hideName && (
            <>
              {/* D */}
              <path
                d="M101.32 144C113.15 144 120.71 137.02 120.71 123.88C120.71 110.74 112.99 103.92 101.32 103.92H92.53V144.01H101.32V144ZM82.02 98.25C82.02 96.03 83.17 94.8 85.39 94.8H101.9C119.89 94.8 131.64 105.48 131.64 123.88C131.64 142.28 119.89 153.12 101.9 153.12H85.39C83.17 153.12 82.02 151.97 82.02 149.67V98.25Z"
                fill={fillColor}
              />
              {/*  A */}
              <path
                d="M168.1 129.54L162.93 113.93C161.94 110.64 160.63 104.89 160.63 104.89H160.46C160.46 104.89 159.15 110.64 158.16 113.93L152.9 129.54H168.1ZM154.05 97.58C154.71 95.69 155.94 94.79 157.91 94.79H163.17C165.14 94.79 166.46 95.69 167.03 97.58L185.27 149.17C186.09 151.55 185.02 153.11 182.48 153.11H178.7C176.73 153.11 175.41 152.21 174.84 150.32L170.73 138.16H150.27L146.24 150.24C145.67 152.21 144.35 153.12 142.38 153.12H138.6C136.05 153.12 134.98 151.56 135.81 149.18L154.05 97.59V97.58Z"
                fill={fillColor}
              />
              <path
                d="M213.35 122.15C219.02 122.15 222.55 118.62 222.55 112.87C222.55 109.17 221.15 106.22 217.95 104.9C216.55 104.24 214.75 103.91 211.62 103.91H203.82V122.15H213.35ZM193.31 98.25C193.31 96.03 194.46 94.8 196.68 94.8H212.45C218.2 94.8 221.07 95.29 223.54 96.36C229.46 98.82 233.32 104.41 233.32 112.3C233.32 119.2 229.71 125.77 223.63 128.24V128.4C223.63 128.4 224.37 129.3 225.52 131.44L235.13 149.02C236.53 151.4 235.46 153.13 232.75 153.13H228.31C226.42 153.13 225.19 152.39 224.28 150.75L214.01 131.37H203.82V149.69C203.82 151.99 202.67 153.14 200.45 153.14H196.67C194.45 153.14 193.3 151.99 193.3 149.69V98.27L193.31 98.25Z"
                fill={fillColor}
              />
              {/* I */}
              <path
                d="M244.73 98.24C244.73 96.02 245.88 94.79 248.1 94.79H251.88C254.1 94.79 255.25 96.02 255.25 98.24V149.66C255.25 151.96 254.1 153.11 251.88 153.11H248.1C245.88 153.11 244.73 151.96 244.73 149.66V98.24Z"
                fill={fillColor}
              />
              {/* A */}
              <path
                d="M295.57 129.54L290.4 113.93C289.41 110.64 288.1 104.89 288.1 104.89H287.94C287.94 104.89 286.63 110.64 285.64 113.93L280.38 129.54H295.58H295.57ZM281.52 97.58C282.18 95.69 283.41 94.79 285.38 94.79H290.64C292.61 94.79 293.93 95.69 294.5 97.58L312.74 149.17C313.56 151.55 312.49 153.11 309.95 153.11H306.17C304.2 153.11 302.88 152.21 302.31 150.32L298.2 138.16H277.74L273.71 150.24C273.14 152.21 271.82 153.12 269.85 153.12H266.07C263.53 153.12 262.46 151.56 263.28 149.18L281.52 97.59V97.58Z"
                fill={fillColor}
              />
              <path
                d="M320.79 98.24C320.79 96.02 321.94 94.79 324.16 94.79H327.94C330.16 94.79 331.31 96.02 331.31 98.24V119.52H358.01V98.24C358.01 96.02 359.16 94.79 361.46 94.79H365.16C367.38 94.79 368.53 96.02 368.53 98.24V149.66C368.53 151.96 367.38 153.11 365.16 153.11H361.46C359.16 153.11 358.01 151.96 358.01 149.66V128.63H331.31V149.66C331.31 151.96 330.16 153.11 327.94 153.11H324.16C321.94 153.11 320.79 151.96 320.79 149.66V98.24Z"
                fill={fillColor}
              />
              <path
                d="M380.01 125.19V122.89C380.01 120.67 381.24 119.52 383.46 119.52H401.28C403.5 119.52 404.73 120.67 404.73 122.89V125.19C404.73 127.49 403.5 128.64 401.28 128.64H383.46C381.24 128.64 380.01 127.49 380.01 125.19Z"
                fill={fillColor}
              />
              {/* L */}
              <path
                d="M426.12 144.52H448.35C450.57 144.52 451.8 145.67 451.8 147.88V150.18C451.8 152.48 450.57 153.63 448.35 153.63H418.98C416.76 153.63 415.62 152.48 415.62 150.18V98.83C415.62 96.62 416.77 95.38 418.98 95.38H422.66C424.87 95.38 426.11 96.61 426.11 98.83V144.52H426.12Z"
                fill={fillColor}
              />
              {/* U */}
              <path
                d="M460.67 98.82C460.67 96.61 461.82 95.37 464.03 95.37H467.72C470.02 95.37 471.16 96.6 471.16 98.82V132.62C471.16 140.5 476.25 144.92 484.04 144.92C491.83 144.92 497 140.49 497 132.62V98.82C497 96.61 498.15 95.37 500.36 95.37H504.13C506.35 95.37 507.49 96.6 507.49 98.82V132.7C507.49 145.74 498.14 154.6 484.11 154.6C470.08 154.6 460.65 145.74 460.65 132.7V98.82H460.67Z"
                fill={fillColor}
              />
            </>
          )}

          <path
            d="M49 21.69C49 11.52 62.1 5.23 70.72 0C77.5 4.13 93.3 11.59 93.3 22.3C93.3 34.18 88.36 53.55 84.42 53.64C80.14 53.73 74.23 29.09 71.08 29.15C67.94 29.22 62.08 53.74 58.13 53.73C53.84 53.73 48.99 32.76 48.99 21.69"
            fill={fillColor}
          />
          <path
            d="M13.91 79.08C4.24 75.94 2.3 61.54 0 51.72C6.02 46.55 18 33.83 28.18 37.13C39.48 40.8 56.38 51.49 55.24 55.26C54.01 59.36 28.74 57.37 27.83 60.38C26.92 63.39 48.43 76.54 47.2 80.29C45.87 84.36 24.43 82.5 13.9 79.08"
            fill={accentColor}
          />
          <path
            d="M57.65 130.19C51.67 138.42 37.38 135.81 27.33 134.97C24.27 127.65 15.88 112.32 22.17 103.66C29.15 94.04 44.54 81.28 47.77 83.53C51.29 85.97 41.58 109.38 44.17 111.18C46.75 112.98 65.9 96.58 69.09 98.91C72.55 101.43 64.16 121.24 57.65 130.2"
            fill={fillColor}
          />
          <path
            d="M114.43 37.32C124.11 34.18 134.14 44.69 141.77 51.28C139.94 59 137.72 76.33 127.54 79.64C116.24 83.32 96.29 84.6 94.99 80.88C93.58 76.84 115.19 63.6 114.16 60.63C113.13 57.66 87.99 59.67 86.78 55.91C85.46 51.83 103.9 40.74 114.43 37.32Z"
            fill={fillColor}
          />
        </g>
        <defs>
          <clipPath id="clip0_2_91">
            <rect width="508" height="155" fill={fillColor} />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export default Logo
