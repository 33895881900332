import React from 'react'
import { useTranslation } from 'react-i18next'
import { AvailableLanguages } from '../utils/i18n'
import { Nav } from 'react-bootstrap'

function LanguageSwitcher() {
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language.split('-').shift()
  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang)
  }
  console.debug('[LanguageSwitcher] currentLanguage:', currentLanguage)
  return (
    <Nav variant="pills">
      {AvailableLanguages.map((lang) => (
        <Nav.Item key={lang}>
          <Nav.Link active={currentLanguage === lang} onClick={() => handleLanguageChange(lang)}>
            {lang}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  )
}

export default LanguageSwitcher
