import i18n from 'i18next'
import { initReactI18next, useTranslation } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import resources from './translations'

// hook to get current language in components
export const useCurrentLanguage = () => {
  const { i18n } = useTranslation()
  return i18n.language.split('-')[0]
}

export const AvailableLanguages = Object.keys(resources)
export const IndexOfDateFormats = {
  short: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },
  long: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },
}
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    detection: {
      order: ['querystring', 'localStorage', 'navigator'],
      caches: ['localStorage'],
      lookupQuerystring: 'lang',
    },
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources,
    languages: Object.keys(resources),
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      format: function (value, format, lng) {
        if (value instanceof Date) {
          const opts = IndexOfDateFormats[format]
            ? IndexOfDateFormats[format]
            : IndexOfDateFormats.long
          return value.toLocaleString(lng === 'en' ? 'en-GB' : lng, opts)
          // return DateTime.fromJSDate(value).setLocale(lng).toFormat(format)
        } else if (typeof value === 'number') {
          return new Intl.NumberFormat(lng, { maximumFractionDigits: format }).format(value)
        }
        return value
      },
    },
  })

console.debug(
  'i18n \n - available languages: ',
  AvailableLanguages,
  '\n - current language: ',
  i18n.language,
)

export default i18n
