import { Col, Container, Row } from 'react-bootstrap'
import { useWindowStore } from '../store'
import './Post.css'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { axiosInstance } from '../utils/api'
import PostCategories from '../components/PostCategories'
import PostCover from '../components/PostCover'
import { useTranslation } from 'react-i18next'
import PostListItem from '../components/PostListItem'

// const FakePost = {
//   date: new Date().toLocaleDateString(),
//   date_gmt: new Date().toISOString(),

//   modified_gmt: new Date().toISOString(),
//   title: { rendered: 'Open Humanities gets an infrastructural boost in Flanders' },
//   excerpt: { rendered: '' },
//   content: { rendered: '' },
// }

const Post = () => {
  const height = useWindowStore((state) => state.height)
  const { t } = useTranslation()
  const { slug } = useParams()
  const safeSlug = slug.replace(/[^a-z0-9-_]/gi, '')

  //
  const { status, data } = useQuery({
    queryKey: ['post-', safeSlug],
    queryFn: ({ signal }) => {
      return axiosInstance
        .get('/posts', {
          signal,
          params: {
            per_page: 1,
            slug: safeSlug,
          },
        })
        .then(({ data }) => (data.length ? data[0] : null))
    },
  })

  const postMainCategory = data?.categories?.find((categoryId) =>
    [
      import.meta.env.VITE_WP_RESOURCES_CATEGORY_ID,
      import.meta.env.VITE_WP_NEWS_EVENTS_CATEGORY_ID,
    ].includes(String(categoryId)),
  )

  // load previous post and next post
  const { status: statusPrevPost, data: prevPost } = useQuery({
    queryKey: ['post-', safeSlug, 'prev'],
    queryFn: ({ signal }) => {
      return axiosInstance

        .get('/posts', {
          signal,
          params: {
            per_page: 1,
            // page: 1,
            order: 'desc',
            orderby: 'date',
            status: 'publish',
            categories: postMainCategory,
            before: data?.date,
          },
        })
        .then(({ data }) => (data.length ? data[0] : null))
    },
    enabled: status === 'success',
  })

  const { status: statusNextPost, data: nextPost } = useQuery({
    queryKey: ['post-', safeSlug, 'next'],
    queryFn: ({ signal }) => {
      return axiosInstance

        .get('/posts', {
          signal,
          params: {
            per_page: 1,
            // page: 1,
            order: 'asc',
            orderby: 'date',
            status: 'publish',
            exclude: data?.id,
            categories: postMainCategory,
            // categories: data?.categories,
            after: data?.date,
          },
        })
        .then(({ data }) => (data.length ? data.pop() : null))
    },
    enabled: statusPrevPost === 'success',
  })

  console.info(
    '[Post] \n - slug:',
    safeSlug,
    '\n - status:',
    status,
    '\n - status prevPost:',
    statusPrevPost,
    '\n - status nextPost:',
    statusNextPost,
    '\n - postMainCategory:',
    postMainCategory,
  )

  const contentWithoutImages = data?.content?.rendered.replace(/<img[^>]*>/g, '')
  const firstParagraph = contentWithoutImages?.split('</p>')[0]
  const allOtherParagraphs = contentWithoutImages?.split('</p>').slice(1).join('</p>')
  const hasCover = data?.content?.rendered.match(/srcset="([^"]*)"/) !== null

  return (
    <div className="Post">
      <section className="hublot-band"></section>
      <section className="py-5 bg-primary "></section>
      <section className="hublot-blind mt-0">
        {/* about us and mission */}
        <Container className="pt-5 px-0" style={{ minHeight: height / 3 }}>
          <Row className="align-items-center">
            {hasCover && (
              <Col md={{ span: 4 }}>
                <div className="square bg-tertiary text-tertiary rounded">
                  <PostCover
                    content={data?.content}
                    size="600w"
                    className="position-absolute h-100"
                  />
                </div>
              </Col>
            )}
            <Col md={{ span: 8 }}>
              <p>{t('dateShort', { date: new Date(data?.date_gmt) })}</p>
              <h1
                dangerouslySetInnerHTML={{
                  __html: data?.title?.rendered,
                }}
              />
              <PostCategories ids={data?.categories} />
            </Col>
          </Row>
          <Row className="py-4">
            <Col md={{ span: 7 }}>
              <div
                className="Post_firstParagraph"
                dangerouslySetInnerHTML={{ __html: firstParagraph }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 9 }}>
              <div className="" dangerouslySetInnerHTML={{ __html: allOtherParagraphs }} />
            </Col>
          </Row>
        </Container>
      </section>
      <section
        className="hublot d-flex align-items-center"
        style={{ minHeight: Math.max(300, height / 3) }}
      >
        <Container className="px-0 py-3">
          <Row>
            <Col md={6}>
              <h2 className="mb-4">{t('previousPost')}</h2>
              {statusPrevPost === 'success' && prevPost !== null && (
                <PostListItem hideCover {...prevPost} />
              )}
            </Col>
            <Col md={6}>
              <h2 className="mb-4">{t('nextPost')}</h2>
              {statusNextPost === 'success' && nextPost !== null && (
                <PostListItem hideCover {...nextPost} />
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default Post
