import './FooterIcon.css'

const InstagramLogo = ({ color = 'var(--primary-color)', width = 24 }) => {
  return (
    <i className="FooterIcon me-2 my-3">
      <svg
        width={width}
        height={width}
        data-name="Instagram Logo"
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.3179 3.14404H10.3179C8.46197 3.14603 6.68264 3.88416 5.37032 5.19649C4.05799 6.50881 3.31986 8.28814 3.31787 10.144V22.144C3.31986 23.9999 4.05799 25.7793 5.37032 27.0916C6.68264 28.4039 8.46197 29.1421 10.3179 29.144H22.3179C24.1738 29.1421 25.9531 28.4039 27.2654 27.0916C28.5778 25.7793 29.3159 23.9999 29.3179 22.144V10.144C29.3159 8.28814 28.5778 6.50881 27.2654 5.19649C25.9531 3.88416 24.1738 3.14603 22.3179 3.14404ZM16.3179 22.144C15.1312 22.144 13.9711 21.7921 12.9844 21.1329C11.9978 20.4736 11.2287 19.5365 10.7746 18.4401C10.3205 17.3438 10.2016 16.1374 10.4332 14.9735C10.6647 13.8096 11.2361 12.7405 12.0752 11.9014C12.9143 11.0623 13.9834 10.4908 15.1473 10.2593C16.3112 10.0278 17.5176 10.1466 18.614 10.6008C19.7103 11.0549 20.6474 11.8239 21.3067 12.8106C21.966 13.7973 22.3179 14.9574 22.3179 16.144C22.3162 17.7348 21.6835 19.26 20.5587 20.3849C19.4338 21.5097 17.9087 22.1424 16.3179 22.144ZM23.8179 10.144C23.5212 10.144 23.2312 10.0561 22.9845 9.89125C22.7378 9.72643 22.5456 9.49216 22.4321 9.21807C22.3185 8.94398 22.2888 8.64238 22.3467 8.35141C22.4046 8.06044 22.5474 7.79316 22.7572 7.58338C22.967 7.3736 23.2343 7.23074 23.5252 7.17286C23.8162 7.11499 24.1178 7.14469 24.3919 7.25822C24.666 7.37176 24.9003 7.56401 25.0651 7.81069C25.2299 8.05736 25.3179 8.34737 25.3179 8.64404C25.3179 9.04187 25.1598 9.4234 24.8785 9.7047C24.5972 9.98601 24.2157 10.144 23.8179 10.144ZM20.3179 16.144C20.3179 16.9352 20.0833 17.7085 19.6437 18.3663C19.2042 19.0241 18.5795 19.5368 17.8486 19.8396C17.1177 20.1423 16.3134 20.2215 15.5375 20.0672C14.7616 19.9128 14.0489 19.5319 13.4894 18.9725C12.93 18.4131 12.5491 17.7003 12.3947 16.9244C12.2404 16.1485 12.3196 15.3442 12.6224 14.6133C12.9251 13.8824 13.4378 13.2577 14.0956 12.8182C14.7534 12.3786 15.5267 12.144 16.3179 12.144C17.3787 12.144 18.3962 12.5655 19.1463 13.3156C19.8964 14.0658 20.3179 15.0832 20.3179 16.144Z"
          fill={color}
        />
      </svg>
    </i>
  )
}

export default InstagramLogo
