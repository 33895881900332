import { useGLTF, MeshTransmissionMaterial } from '@react-three/drei'
import React, { useEffect, useRef } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { MathUtils, MeshNormalMaterial } from 'three'
import { WpThemeDirPath } from '../constants'

export function FlowerModel({ disableScroll = false, ...props }) {
  const mesh = useRef()
  const mouse = useRef([0, 0])
  const { nodes } = useGLTF(`${WpThemeDirPath}Flower.glb`)
  const { invalidate } = useThree()

  // Subscribe this component to the render-loop, rotate the mesh every frame
  // useFrame((state, delta) => (mesh.current.rotation.y += delta / 50));
  // use scrolling to change the rotation
  useEffect(() => {
    const onScroll = (e) => {
      // use Math.lerp to smooth the rotation
      mesh.current.rotation.x = (window.scrollY * Math.PI) / 2000
      invalidate()
    }
    if (!disableScroll) {
      window.addEventListener('scroll', onScroll)
    }

    return () => window.removeEventListener('scroll', onScroll)
  }, [disableScroll])

  return (
    <group {...props} dispose={null} ref={mesh}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane002.geometry}
        material={nodes.Plane002.material}
      />

      {/* <MeshTransmissionMaterial
          resolution={768}
          thickness={1}
          anisotropy={0.5}
          chromaticAberration={0.1}
        /> */}
      {/* <meshPhysicalMaterial
          color={'black'}
          transmission={0}
          roughness={0}
          ior={1}
          thickness={0.5}
          specularIntensity={0.1}
          clearcoat={1}
        /> */}
      {/* <meshStandardMaterial /> */}
    </group>
  )
}
useGLTF.preload(`${WpThemeDirPath}Flower.glb`)
