import { Container, Row, Col } from 'react-bootstrap'
import { useWindowStore } from '../store'
import ListOfFilterableItems from '../components/ListOfFilterableItems'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../utils/api'
import PostListItem from '../components/PostListItem'
import { useCurrentLanguage } from '../utils/i18n'
import { BootstrapColumnLayout } from '../constants'

const Category = ({
  titleLabel = 'untitledCategory',
  tabs = [],
  defaultTab = { slug: 'all' },
  params = {},
  categoryRoute = { to: '' },
  hideCover = false,
}) => {
  const { t } = useTranslation()
  const language = useCurrentLanguage()
  const height = useWindowStore((state) => state.height)
  const { category } = useParams()
  const safeCategory = tabs.map((d) => d.slug).includes(category) ? category : defaultTab.slug
  const safeCategoryId = tabs.find((d) => d.slug === safeCategory)?.id
  // load latest resources
  const { status, error, data } = useQuery({
    queryKey: ['latest-posts', safeCategory],
    queryFn: ({ signal }) => {
      return axiosInstance
        .get('/posts', {
          signal,
          params,
        })
        .then(({ data }) => data)
    },
  })

  // cascade load page content
  const {
    status: statusPageContent,
    error: errorPageContent,
    data: pageContents,
  } = useQuery({
    queryKey: ['category', safeCategory, language],
    queryFn: ({ signal }) => {
      return axiosInstance
        .get('/pages', {
          signal,
          params: {
            slug: safeCategory + '-' + language,
            per_page: 1,
          },
        })
        .then(({ data }) => data)
    },
    enabled: status === 'success',
  })

  // cascade load nested resources
  console.debug(
    '[Category:page] \n - height:',
    height,
    '\n - category (from params):',
    category,
    '\n - category (safe):',
    safeCategory + '-' + language,
    '\n - latest resources request status:',
    status,
    '\n - page contents request status:',
    statusPageContent,
    error,
    data?.length,
    pageContents,
  )
  return (
    <div className="Category">
      <section className="hublot" style={{ minHeight: height / 3 }}>
        <Container style={{ paddingTop: 100 }}>
          <Row>
            <Col {...BootstrapColumnLayout}>
              <h1
                className="text-end"
                dangerouslySetInnerHTML={{
                  __html: t(titleLabel),
                }}
              />
              {status === 'error' && <p>Error: {error.message}</p>}
              <ol className="p-0 my-4">
                {status === 'success' &&
                  data.map((post) => (
                    <li key={post.id}>
                      <PostListItem hideCover={hideCover} key={post.id} {...post} />
                    </li>
                  ))}
              </ol>
            </Col>
          </Row>
        </Container>
      </section>

      <ListOfFilterableItems
        tabs={tabs.map((cat) => ({
          name: cat.slug,
          label: t(`category-${cat.slug}`),
          slug: cat.slug,
          isActive: cat.slug === safeCategory,
          to: cat.slug === defaultTab.slug ? categoryRoute.to : categoryRoute.to + '/' + cat.slug,
        }))}
        enabled={statusPageContent === 'success'}
        url={'/posts'}
        params={{
          categories: safeCategoryId,
        }}
        style={{ minHeight: height / 2, color: 'white' }}
      >
        <Container className="px-4 px-lg-5 pt-5">
          {statusPageContent === 'error' && <p>Error: {errorPageContent.message}</p>}
          {statusPageContent === 'loading' && <p>...</p>}
          {statusPageContent === 'success' && pageContents.length > 0 && (
            <Row>
              <Col md={{ span: 9 }}>
                <h2>{pageContents[0].title.rendered}</h2>
                <blockquote
                  dangerouslySetInnerHTML={{ __html: pageContents[0].content.rendered }}
                />
              </Col>
            </Row>
          )}
        </Container>
      </ListOfFilterableItems>
    </div>
  )
}

export default Category
