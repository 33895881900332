/**
 * THis module contains a trigger for the mobile header.
 */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LogoPetals from './svg/LogoPetals'
import './MobileHeader.css'
import MobileMenu from './MobileMenu'
import { useLocation } from 'react-router-dom'
import { useStore } from '../store'

const MobileHeader = () => {
  const { t } = useTranslation()
  const [isMenuOpen, setIsMenuOpen] = useStore((state) => [state.isMenuOpen, state.setIsMenuOpen])

  const handleClick = () => {
    setIsMenuOpen(!isMenuOpen)
  }
  return (
    <>
      <div className={`MobileHeader ${isMenuOpen ? 'active' : ''}`}>
        <LogoPetals accentColor={isMenuOpen ? 'var(--primary-color)' : 'var(--accent-color)'} />
        <button type="button" className="MobileHeaderMenuButton" onClick={handleClick}>
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
      <MobileMenu handleClick={handleClick} />
    </>
  )
}

export default MobileHeader
