import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { AnalyticsCookiesAccepted, CookiesAccepted, CookiesNotYetAccepted } from './constants'

export const useWindowStore = create((set) => ({
  width: window.innerWidth,
  height: window.innerHeight,
  memo: [window.innerWidth, window.innerHeight].join('x'),
  updateDimensions: () => {
    const width = window.innerWidth
    const height = window.innerHeight
    const memo = [width, height].join('x')
    console.debug('[store] updateDimensions', memo)
    return set({ width, height, memo })
  },
}))

export const useStore = create((set) => ({
  categories: [],
  categoriesMap: {},
  setCategories: (categories) => {
    console.info('[store] setCategories \n', categories.map((category) => category.slug).join(', '))
    const categoriesMap = categories.reduce((acc, category) => {
      acc[category.id] = category
      return acc
    }, {})
    return set({ categories, categoriesMap })
  },
  logoFillColor: 'red',
  setLogoFillColor: (logoFillColor) => {
    console.info('[store] setLogoFillColor \n', logoFillColor)
    return set({ logoFillColor })
  },
  backgroundColor: '#333357',
  setBackgroundColor: (backgroundColor) => {
    console.info('[store] setBackgroundColor \n', backgroundColor)
    return set({ backgroundColor })
  },
  isMenuOpen: false,
  setIsMenuOpen: (isMenuOpen) => {
    console.info('[store] setIsMenuOpen \n', isMenuOpen)
    return set({ isMenuOpen })
  },
}))

export const usePersistentStore = create(
  persist(
    (set, get) => ({
      acceptAnalyticsCookies: AnalyticsCookiesAccepted,
      acceptCookies: CookiesNotYetAccepted, // cookies should be accepted, session is stored locally
      setAcceptAnalyticsCookies: (acceptAnalyticsCookies) => {
        console.info('[store] setAcceptAnalyticsCookies', acceptAnalyticsCookies)
        return set({ acceptAnalyticsCookies })
      },
      setAcceptCookies: () => {
        console.info('[store] setAcceptCookies')
        return set({ acceptCookies: CookiesAccepted })
      },
    }),
    {
      name: import.meta.env.VITE_APP_NAME, // name of the item in the storage (must be unique)
    },
  ),
)
