import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../utils/api'
import { Col, Container, Row } from 'react-bootstrap'
import { useCurrentLanguage } from '../utils/i18n'
import './Page.css'
import { useLayoutEffect } from 'react'

const Page = ({ slug: forceSlug, otherParagraphsInHublot = false, children }) => {
  const { slug } = useParams()
  const language = useCurrentLanguage()
  const safeSlug = [(forceSlug || slug).replace(/[^a-z0-9-]/gi, ''), language].join('-')

  const { status, data } = useQuery({
    queryKey: ['page-', safeSlug, language],
    queryFn: ({ signal }) => {
      return axiosInstance
        .get('/pages', {
          signal,
          params: {
            per_page: 1,
            slug: safeSlug,
          },
        })
        .then(({ data }) => (data.length ? data[0] : null))
    },
  })
  console.debug('[Page] slug:', safeSlug, status)

  useLayoutEffect(() => {
    status === 'success' &&
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
  }, [status])

  const contentWithoutImages = data?.content?.rendered.replace(/<img[^>]*>/g, '')
  const firstParagraph = contentWithoutImages?.split('</p>')[0]
  const allOtherParagraphs = contentWithoutImages?.split('</p>').slice(1)
  // table of contents
  return (
    <div className="Page">
      <section className="hublot-band"></section>
      <section className="bg-primary py-5"></section>
      <section className="hublot-blind my-0">
        {' '}
        <Container>
          <Row className="py-5">
            <Col lg={{ span: 5 }}>
              <h1
                dangerouslySetInnerHTML={{
                  __html: data?.title?.rendered,
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 8 }}>
              <div
                className="Post_firstParagraph"
                dangerouslySetInnerHTML={{ __html: firstParagraph }}
              />
            </Col>
          </Row>
          {!otherParagraphsInHublot && (
            <Row>
              <Col md={{ span: 9 }}>
                {Array.isArray(allOtherParagraphs) && (
                  <div
                    className="my-4"
                    dangerouslySetInnerHTML={{ __html: allOtherParagraphs.join('</p>') }}
                  />
                )}
              </Col>
            </Row>
          )}
        </Container>
      </section>
      <section className="hublot py-5 text-primary" style={{ minHeight: window.innerHeight / 3 }}>
        {children}
        {Array.isArray(allOtherParagraphs) && otherParagraphsInHublot && (
          <Container>
            {allOtherParagraphs.map((paragraph, index) => (
              <Row key={index}>
                <Col md={{ span: 9 }}>
                  <div className="my-4" dangerouslySetInnerHTML={{ __html: paragraph }} />
                </Col>
              </Row>
            ))}
          </Container>
        )}
      </section>
    </div>
  )
}

export default Page
